import { PureComponent } from "react";
import PropTypes from "prop-types";
import styles from "./ButtonMenu.module.scss";
import { NavLink } from "react-router-dom";

class ButtonMenu extends PureComponent {
  render() {
    const { children, onClick, linkGo } = this.props;

    return (
      <>
        <NavLink
          to={`/${linkGo}`}
          style={({ isActive, isPending }) => {
            return {
              fontWeight: isActive ? "bold" : "",
              color: isPending ? "darkgrey" : "black",
              textDecoration: "none",
            };
          }}
        >
          <button onClick={onClick} className={styles.btn}>
            {children}
          </button>
        </NavLink>
      </>
    );
  }
}

ButtonMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onClick: PropTypes.func,
  linkGo: PropTypes.string,
};

ButtonMenu.defaultProps = {
  onClick: () => {},
};

export default ButtonMenu;
