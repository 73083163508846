import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styles from './PrpsButton.module.scss';
import {NavLink} from 'react-router-dom';


class PrpsButton extends PureComponent {

    render() {
        const {children, onClick, gridArea, linkPath} = this.props;

        return (
            <div className={styles.prpsBtn} id='purpose'>
                 <NavLink to={`/${linkPath}`}
                   style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                    };
                  }}
                  >
            <button
                onClick={onClick}
                className={`${styles.btn} ${gridArea}`}
                
            >
                {children}
            </button>
            </NavLink>
            </div>
        )
    }

}

PrpsButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    onClick: PropTypes.func,
    gridArea: PropTypes.node,
    linkPath: PropTypes.string
};

PrpsButton.defaultProps = {
    onClick: () => {
    }
};

export default PrpsButton;