import { PureComponent } from "react";
import styles from "./WhyWe.module.scss";
import CountCard from "../CountCard/CountCard";


class WhyWe extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.root} id='describe'>
      <CountCard lang={lang} />
      </div>
    );
  }
}

export default WhyWe;
