import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./CustomInputText.module.scss";

const CustomInputText = (props) => {
  const { placeholder, field, form } = props;
  const { name, value } = field;
  const { touched, errors } = form;

  return useMemo(
    () => (
      <div className={styles.root}>
        {/* {label && <label htmlFor={name}>{label}</label>} */}
        {/* <input
                name={name}
                id={name}
                type={type}
                placeholder={placeholder}
                value={value}
                {...field}
            /> */}
        <textarea
          name={name}
          placeholder={placeholder}
          rows={4}
          cols={40}
          wrap="soft"
          {...field}
        />
        <span className="error">
          {touched[name] && errors[name] ? errors[name] : ""}
        </span>
      </div>
      
    ),
    // eslint-disable-next-line
    [value, touched, errors]
  );
};

CustomInputText.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
};
CustomInputText.defaultProps = {
  placeholder: "",
};

export default CustomInputText;
