import { PureComponent } from "react";
import styles from "./EngineeringPrj.module.scss";
import Footer from "../../components/Footer/Footer";
import engineer_proj_1 from "../../img/engineering-1.jpg";
import engineer_proj_2 from "../../img/engineering-2.jpg";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

class EngineeringPrj extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.engineRoot}>
        <Helmet>
          <title>
            {lang ? "ENGINEERING DESIGN" : "Інженерне проєктування"}
          </title>
          <meta
            name="description"
            content="Головне завдання D8engineering при інженерному проєктуванні є задоволення потреб людини, яка перебуває в умовах певної будівлі чи споруди | Інженерне проектування в D8 виконується на об'єктах буль-якої складності | Звернувшись до нас, ви отримаєте всі переваги індивідуального підходу, який ми демонструємо до кожного клієнта | Crestron"
          />
          <link rel="canonical" href="https://d8.com.ua/engineeringdesign" />
        </Helmet>
        <div className={styles.engineer_proj}>
          <div className={styles.engineer_proj_dark_1}>
            <div className={styles.engineer_proj_palochka}></div>
            <h1 className={styles.engineer_proj_text_chapter}>
              {lang ? "ENGINEERING DESIGN" : "ІНЖЕНЕРНЕ ПРОЄКТУВАННЯ"}
            </h1>
            <p className={styles.engineer_proj_describe_chapter}>
              {lang
                ? "In today's world, no building or structure can do without various types of communications - water supply, heating and ventilation networks, gas supply, electricity, communication networks and other types of engineering systems that facilitate and make a person's stay in this building comfortable"
                : "В умовах сучасного світу жодна будівля або споруда не може обійтися без влаштування в ній різного виду комунікацій - мереж водопостачання, опалення та вентиляції, газопостачання, електропостачання, мереж зв'язку та інших видів інженерних систем, що полегшують та роблять комфортним перебування людини в даній споруді"}
            </p>
          </div>
          <div className={styles.engineer_proj_dark_2}>
            <img
              className={styles.engineer_proj_foto_1}
              src={engineer_proj_1}
              alt="engineering design projects maps"
            ></img>
            <div className={styles.engineer_proj_paragraph_1}>
              {lang
                ? "For us, the main task in engineering design is to meet the needs of a person who is in a particular building or structure. It is very important that the designed engineering systems fit correctly into the building concept, are safe and reliable during the operation of the building. That is why the engineering design of all systems is carried out jointly with specialized specialists.<br></br><br></br> Engineering design at D8 is carried out at facilities of any complexity. Extensive experience and highly qualified specialists allow us to perform all engineering design work promptly and at a consistently high level of quality. We are able to develop not only standard engineering projects, but also non-standard ones that require unique engineering and creative solutions."
                : " Для нас головним завданням при інженерному проєктуванні є задоволення потреб людини, яка перебуває в умовах певної будівлі чи споруди. Дуже важливо, щоб інженерні системи, що проєктуються, грамотно вписувалися в концепцію будівлі, були безепечні і надійні при експлуатації будівлі. Саме тому інженерне проєктування всіх систем здійснюється спільно з профільними спеціалістами."} 
                  {lang
                ? " Engineering design at D8 is carried out at facilities of any complexity. Extensive experience and highly qualified specialists allow us to perform all engineering design work promptly and at a consistently high level of quality. We are able to develop not only standard engineering projects, but also non-standard ones that require unique engineering and creative solutions."
                :  "Інженерне проєктування в D8 виконується на об'єктах будь-якої складності. Великий досвід та наявність у штаті висококваліфікованих фахівців дозволяє нам виконувати всі роботи з інженерного проєктування оперативно та на стабільно високому рівні якості. Нам доступна розробка не тільки типових інженерних проектів, а й нестандартних, що потребують унікальних інженерних та креативних рішень."} 
            </div>
          </div>
          <div className={styles.engineer_proj_dark_3}>
            <img
              className={styles.engineer_proj_foto_2}
              src={engineer_proj_2}
              alt="engineering design projects"
            ></img>
            <div className={styles.engineer_proj_paragraph_2}>
              {lang
                ? "By contacting us, you will receive all the benefits of the individual approach we demonstrate to each client."
                : "Звернувшись до нас, ви отримаєте всі переваги індивідуального підходу, який ми демонструємо до кожного клієнта."}

              <br></br>
              <br></br>
              {lang
                ? "Not only can we develop a project, but we can also bring it to life, as evidenced by the many engineering solutions we have successfully implemented over the years."
                : "Ми можемо не тільки розробити проект, але й зможемо втілити його в життя, про що свідчить безліч успішно реалізованих за час роботи інженерних рішень"}
            </div>
          </div>
          <div className={styles.engineer_proj_dark_4}>
            <div className={styles.engineer_proj_light_palochka_1}></div>

            <div className={styles.engineer_proj_paragraph_3}>
              {lang
                ? "OUR PROJECTS CAN BE VIEWED HERE"
                : "НАШІ ПРОЄКТИ МОЖНА ПОДИВИТИСЯ ТУТ"}
            </div>

            <NavLink
              to={"/ourProjects"}
              style={({ isActive, isPending }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isPending ? "darkgrey" : "black",
                  textDecoration: "none",
                };
              }}
            >
              <button className={styles.engineer_proj_btn_1}>
                {lang ? "OUR PROJECTS" : "НАШІ ПРОЄКТИ"}
              </button>
            </NavLink>
          </div>
          <Footer lang={lang} />
        </div>
      </div>
    );
  }
}

export default EngineeringPrj;
