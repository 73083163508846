import { PureComponent } from "react";
import cur_prj_1 from "../../../img/Penthouse Skyline/pentSky_1.jpg";
import cur_prj_2 from "../../../img/Penthouse Skyline/pentSky_2.jpg";
import cur_prj_3 from "../../../img/Penthouse Skyline/pentSky_3.jpg";
import cur_prj_4 from "../../../img/Penthouse Skyline/pentSky_4.jpg";
import cur_prj_5 from "../../../img/Penthouse Skyline/pentSky_5.jpg";
import cur_prj_6 from "../../../img/Penthouse Skyline/pentSky_6.jpg";
import cur_prj_7 from "../../../img/Penthouse Skyline/pentSky_7.jpg";
import cur_prj_8 from "../../../img/Penthouse Skyline/pentSky_8.jpg";
import cur_prj_9 from "../../../img/Penthouse Skyline/pentSky_9.jpg";
import cur_prj_10 from "../../../img/Penthouse Skyline/pentSky_10.jpg";
import cur_prj_11 from "../../../img/Penthouse Skyline/pentSky_11.jpg";
import cur_prj_12 from "../../../img/Penthouse Skyline/pentSky_12.jpg";
import cur_prj_13 from "../../../img/Penthouse Skyline/pentSky_13.jpg";
import cur_prj_14 from "../../../img/Penthouse Skyline/pentSky_14.jpg";
import cur_prj_15 from "../../../img/Penthouse Skyline/pentSky_15.jpg";
import cur_prj_16 from "../../../img/Penthouse Skyline/pentSky_16.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class PenthouseSkyLine extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [
      cur_prj_1,
      cur_prj_2,
      cur_prj_3,
      cur_prj_4,
      cur_prj_5,
      cur_prj_6,
      cur_prj_7,
      cur_prj_8,
      cur_prj_9,
      cur_prj_10,
      cur_prj_11,
      cur_prj_12,
      cur_prj_13,
      cur_prj_14,
      cur_prj_15,
      cur_prj_16,
    ];

    const idUniq = 237;

    const textDescribe =
      "Система домашньої автоматизації у цьому проєкті реалізовує такі завдання - керування освітленням, системою опалювання та теплої підлоги, шторами та музикою. У систему управління Crestron інтегровано мультимедійну систему Sonos. Встановлено аварійний контроль для уникнення протікання та інших аварійних ситуацій. За допомогою встоновлених елементів управління «розумний дім» власник може одиним дотиком змінити світлові сценарії в приміщенні, або використовуючи весь доступний функціонал для управління, створити бажаний настрій у квартирі. Архітектор Сергій Махно.";

    const textDescribeEng =
      "The home automation system in this project implements the following tasks: controlling lighting, heating and underfloor heating, curtains, and music. The Sonos multimedia system is integrated into the Crestron control system. Emergency control is installed to avoid leaks and other emergencies. With the help of the installed 'smart home' controls, the owner can change the lighting scenarios in the room with a single touch, or using all the available control functionality, create the desired mood in the apartment. Architect Sergey Makhno.";

    return (
      <>
        <CurrentPrj
          name="Penthouse SkyLine"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default PenthouseSkyLine;
