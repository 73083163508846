import { PureComponent } from "react";
import styles from "./MainInfo.module.scss";
import { NavLink } from "react-router-dom";
import CreativeView from "../CreativeView/CreativeView";
import LookAtUs from "../LookAtUs/LookAtUs";
import WhyWe from "../WhyWe/WhyWe";
import OurPartners from "../OurPartners/OurPartners";
import SliderPrj from "../SliderPrj/SliderPrj.js";
import LetsTell from "../LetsTell/LetsTell";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import OurBrands from "../OurBrands/OurBrands.js";

class MainInfo extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div>
        <Banner lang={lang} />
        <div className={styles.root}>
          <SliderPrj />
          <div className={styles.title} id="aboutUs">
            <h1 className={styles.firstTitle}>
              {lang ? "Automatizing" : "Автоматизуємо"}
            </h1>
            <h1 className={styles.secondTitle}>
              {lang ? "EVERY ENVIRONMENT" : "кожне середовище"}
            </h1>
            <div className={styles.titleQuote}>
              {lang
                ? "Design is not just how it looks or feels, design is how it works"
                : "  Дизайн – це не тільки те, як він виглядає або відчувається, дизайн – це те, як він працює"}
              <div className={styles.titleQuote_Line}>|</div>
              <div className={styles.titleQuote_Author}>
                {lang ? "Steve Jobs" : "Стів Джобс"}
              </div>
            </div>
          </div>
          <div className={styles.mainText}>
            <div className={styles.mainText_first}>
              {lang
                ? "D8 is an integrator of automation systems for residential and commercial facilities. Prioritizing simplicity and user-friendliness in the operation of the control system and setting the highest requirements for the reliability and reliability of the systems and equipment used, D8 has been a leader in the automation market for many years, which is confirmed by the unique status of Crestron Elite Pro Partner awarded in 2020."
                : "D8 - інтегратор систем автоматизації житлових та комерційних об'єктів. Ставлячи у пріоритеті простоту та зручність для користувача в процесі експлуатації системи управління та висуваючи найвищі вимоги до надійності та безвідмовності використовуваних систем та обладнання, D8 вже багато років займає лідируючі позиції на ринку автоматизації, що підтверджено присвоєним у 2020 році унікальним для України статусом Crestron Elite Pro Partner."}
            </div>
            <div className={styles.mainText_second}>
              {lang
                ? "The organization of the comfort of modern housing places more and more demands on its compliance with our rhythm of life and incoming tasks. Centralized automation and control systems are designed to solve these tasks without human intervention, offering resource and time savings, ease of management, regardless of whether you are at home or not. But a smart home is only our assistant when it is easy to use and invisible in operation."
                : "Організація комфорту сучасного житла виставляє все більше вимог до його відповідності нашому ритму життя та завданням, що надходять. Централізовані системи автоматизації та управління покликані вирішувати ці завдання без участі людини, пропонуючи економію ресурсів та часу, зручність в управлінні незалежно від того вдома ви чи ні. Але розумний будинок лише тоді наш помічник, коли він простий у використанні та непомітний у роботі."}
            </div>
          </div>
          <div className={styles.btnOurVis}>
            <NavLink to="/ourvision">
              <button>{lang ? "Our vision" : "Наше бачення"}</button>
            </NavLink>
          </div>
          <CreativeView lang={lang} />
          <OurBrands/>
          <LookAtUs lang={lang} />
          <WhyWe lang={lang} />
          <OurPartners lang={lang} />
          <LetsTell lang={lang} />
          <Footer id="footer" lang={lang} />
        </div>
      </div>
    );
  }
}

export default MainInfo;
