import { PureComponent } from "react";
import styles from "./EnergyEfficient.module.scss";
import Footer from "../../components/Footer/Footer";
import energy_1 from "../../img/energy-1.jpg";
import energy_2 from "../../img/energy-2.jpg";
import energy_3 from "../../img/energy-3.jpg";
import energy_4 from "../../img/energy-4.jpg";
import energy_5 from "../../img/energy-5.jpg";
import { Helmet } from "react-helmet";

class EnergyEfficient extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.engineRoot}>
        <Helmet>
          <title>
            {lang ? "ENERGY-EFFICIENT SOLUTIONS" : "Енергоефективні рішення"}
          </title>
          <meta
            name="description"
            content="Енергоефективність - це рішення щодо зниження споживання енергії та інших комунікальних ресурсів | За допомого сенсорів і алгоритмів штучного інтелекту, ви можете автоматично регулювати освітлення, температуру, системи кондиціонування повітря та інші пристрої, мінімізуючи втрати і надмірне споживання | Crestron"
          />
          <link rel="canonical" href="https://d8.com.ua/energyefficientdecision" />
        </Helmet>
        <div className={styles.engineer_proj}>
          <div className={styles.energy}>
            <div className={styles.energy_dark_1}>
              <div className={styles.energy_palochka}></div>
              <h1 className={styles.energy_text_chapter}>
                {lang
                  ? "ENERGY-EFFICIENT SOLUTIONS"
                  : "ЕНЕРГОЕФЕКТИВНІ РІШЕННЯ"}
              </h1>
              <p className={styles.energy_describe_chapter}>
                {lang
                  ? "Energy efficiency is a solution to reduce the consumption of energy and other communication resources by using new, less energy-intensive equipment, optimizing existing systems, and installing metering devices, which makes it possible to use energy resources rationally"
                  : "Енергоефективність - це рішення щодо зниження споживання енергії та інших комунікальних ресурсів, за рахунок використання нового, менш енергоємного устаткування, оптимізації наявних систем, встановлення приладів обліку, що дає можливість раціонально використовувати енергоресурси"}
              </p>
            </div>
            <div className={styles.energy_light_1}>
              <img
                className={styles.energy_foto_1}
                src={energy_1}
                alt="energy life green"
              ></img>
              <div className={styles.energy_dark_palochka_1}></div>
              <div className={styles.energy_article_1}>
                {lang ? "EFFICIENCY" : "ЕФЕКТИВНІСТЬ"}
              </div>
              <div className={styles.energy_paragraph_1}>
                {lang
                  ? "In an era of rapid technological development, understanding the importance of energy conservation and efficient use of resources is becoming more relevant than ever. As part of this trend, the concept of space automation combines advanced technologies and innovative approaches to resource management, creating a comfortable and environmentally responsible living space."
                  : "В епоху швидкого технологічного розвитку, розуміння важливості енергозбереження та ефективного використання ресурсів стає більш актуальним, ніж будь-коли. В рамках цієї тенденції, концепція автоматизації простору, поєднує в собі передові технології та інноваційні підходи до управління ресурсами, створюючи комфортний і екологічно відповідальний простір для життя."}
              </div>
            </div>
            <div className={styles.energy_light_2}>
              <img
                className={styles.energy_foto_2}
                src={energy_2}
                alt="energy project communications"
              ></img>
              <div className={styles.energy_dark_palochka_2}></div>
              <div className={styles.energy_article_2}>
                {lang
                  ? "SMART ENERGY MANAGEMENT"
                  : "РОЗУМНЕ УПРАВЛІННЯ ЕНЕРГОСПОЖИВАННЯМ"}
              </div>
              <div className={styles.energy_paragraph_2}>
                {lang
                  ? "Intelligent energy monitoring and management systems help you optimize the use of electricity, water, and other resources in your home. With the help of sensors and artificial intelligence algorithms, you can automatically adjust lighting, temperature, air conditioning, and other devices to minimize waste and excessive consumption."
                  : "Інтелектуальні системи моніторингу та управління енергоспоживанням дають змогу оптимізувати використання електроенергії, води та інших ресурсів у вашому домі. За допомогою сенсорів і алгоритмів штучного інтелекту, ви можете автоматично регулювати освітлення, температуру, системи кондиціонування повітря та інші пристрої, мінімізуючи втрати і надмірне споживання."}
              </div>
            </div>
            <div className={styles.energy_light_3}>
              <img
                className={styles.energy_foto_3}
                src={energy_3}
                alt="energy light electricity"
              ></img>
              <div className={styles.energy_dark_palochka_3}></div>
              <div className={styles.energy_article_3}>
                {lang ? "EFFICIENT LIGHTING" : "ЕФЕКТИВНЕ ОСВІТЛЕННЯ"}
              </div>
              <div className={styles.energy_paragraph_3}>
                {lang
                  ? "Our lighting solutions allow you to control different light sources, change their brightness and color temperature. This not only creates a pleasant atmosphere, but also reduces energy consumption by fine-tuning the lighting to suit the time of day and needs."
                  : "Наші рішення в галузі освітлення дають можливість керувати різними джерелами світла, змінювати їхню яскравість і колірну температуру. Це не тільки створює приємну атмосферу, а й дає змогу скоротити енергоспоживання завдяки точному налаштуванню освітлення залежно від часу доби та потреб."}
              </div>
            </div>
            <div className={styles.energy_light_4}>
              <img
                className={styles.energy_foto_4}
                src={energy_4}
                alt="energy window native light"
              ></img>
              <div className={styles.energy_dark_palochka_4}></div>
              <div className={styles.energy_article_4}>
                {lang
                  ? "SMART CLIMATE MANAGEMENT"
                  : "ІНТЕЛЕКТУАЛЬНЕ УПРАВЛІННЯ КЛІМАТОМ"}
              </div>
              <div className={styles.energy_paragraph_4}>
                {lang
                  ? "Our climate control solution helps to maintain a comfortable indoor environment by optimizing the performance of heating and air conditioning systems. Based on data analysis and user preferences, the system can automatically adapt settings for optimal efficiency and comfort."
                  : "Наше рішення для кліматичного контролю дають змогу підтримувати комфортні умови в приміщенні, оптимізуючи роботу систем опалення та кондиціонування. Ґрунтуючись на аналізі даних і вподобаннях користувачів, система може автоматично адаптувати параметри для оптимальної ефективності та комфорту."}
              </div>
            </div>
            <div className={styles.energy_dark_5}>
              <div className={styles.energy_light_palochka_5}></div>
              <div className={styles.energy_article_5}>
                {lang
                  ? "INTEGRATION OF RENEWABLE ENERGY SOURCES"
                  : "ІНТЕГРАЦІЯ ПОНОВЛЮВАЛЬНИХ ДЖЕРЕЛ ЕНЕРГІЇ"}
              </div>
              <div className={styles.energy_paragraph_5}>
                {lang
                  ? "Connect solar panels, wind turbines, or other renewable energy sources to your home automation systems. This will not only allow you to save on electricity bills and ensure an uninterrupted supply of electricity, but also contribute to environmental protection and conservation of the planet's resources."
                  : "Підключіть сонячні панелі, вітрогенератори або інші поновлювані джерела енергії до систем автоматизації будинку. Це дасть вам змогу не тільки заощадити на рахунках за електроенергію та забезпечити безперебійну подачу електроенергії, а й робити свій внесок в охорону довкілля та збереження ресурсів планети."}
              </div>
              <img
                className={styles.energy_foto_5}
                src={energy_5}
                alt="energy solar panels alternative energy"
              ></img>
            </div>
          </div>
          <Footer lang={lang} />
        </div>
      </div>
    );
  }
}

export default EnergyEfficient;
