import { PureComponent } from "react";
import propTypes from "prop-types";
import Iframe from "react-iframe";
import styles from "./Youtube.module.scss";

// const YoutubeEmbed = ({ embedId }) => (

//   <div className={styles.videoResponsive}>
//        <animated.div
//       className={styles.videoResponsive}
//       style={props3}
//       onMouseEnter={() => setShown(true)}
//       onMouseLeave={() => setShown(false)}
//     >
//     <Iframe
//     width="853"
//     height="480"
//     src={`https://www.youtube.com/embed/${embedId}`}
//     frameBorder="0"
//     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//     allowFullScreen
//     title="Embedded youtube"
//     />
//     </animated.div>
//   </div>

// )

class YoutubeEmbed extends PureComponent {
  render() {
    const { embedId, height, width } = this.props;

    return (
      <div className={styles.videoResponsive}>
        <Iframe
          width={width}
          height={height}
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    );
  }
}

YoutubeEmbed.propTypes = {
  embedId: propTypes.string.isRequired,
  height: propTypes.string.isRequired,
  width: propTypes.string.isRequired,
};


export default YoutubeEmbed;

// width = "256";
// height = "144";
