import { PureComponent } from "react";
import styles from "./Header.module.scss";

class Header extends PureComponent {
  render() {
    const { toggleModal, lang, toggleLang } = this.props;

    return (
      <header className={styles.root}>
        <div className={styles.headerLine}></div>
        <div
          className={styles.menuBtn}
          onClick={() => {
            toggleModal(true);
          }}
        >
          <span className={styles.lineFirst}></span>
          <span className={styles.lineSecond}></span>
          <span className={styles.lineThird}></span>
        </div>
        <div className={styles.menuLanguage}>
          <button
            className={styles.menuLanguage_btn}
            onClick={() => {
              toggleLang(lang);
            }}
          >
            {lang ? "UA" : "EN"}
          </button>
        </div>
      </header>
    );
  }
}

export default Header;
