import { PureComponent } from "react";
import cur_prj_1 from "../../../img/DiamondHill/diamondHill_1.jpg";
import cur_prj_2 from "../../../img/DiamondHill/diamondHill_2.jpg";
import cur_prj_3 from "../../../img/DiamondHill/diamondHill_3.jpg";
import cur_prj_4 from "../../../img/DiamondHill/diamondHill_4.jpg";
import cur_prj_5 from "../../../img/DiamondHill/diamondHill_5.jpg";
import cur_prj_6 from "../../../img/DiamondHill/diamondHill_6.jpg";
import cur_prj_7 from "../../../img/DiamondHill/diamondHill_7.jpg";
import cur_prj_8 from "../../../img/DiamondHill/diamondHill_8.jpg";
import cur_prj_9 from "../../../img/DiamondHill/diamondHill_9.jpg";
import cur_prj_10 from "../../../img/DiamondHill/diamondHill_10.jpg";
import cur_prj_11 from "../../../img/DiamondHill/diamondHill_11.jpg";
import cur_prj_12 from "../../../img/DiamondHill/diamondHill_12.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class DiamondHill extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [
      cur_prj_1,
      cur_prj_2,
      cur_prj_3,
      cur_prj_4,
      cur_prj_5,
      cur_prj_6,
      cur_prj_7,
      cur_prj_8,
      cur_prj_9,
      cur_prj_10,
      cur_prj_11,
      cur_prj_12
    ];

    const idUniq = 241;

    const textDescribe =
      "У проєкті використовуються лише передові технології: керування природним і штучним освітленням (ролети), кліматомб теплими підлогами. Особливостями інтегроввана система спостереження та аудіо система, домашній кінотеатр. А родзинкою є кабінет, який швидко можна перетворити на конференц-залу в якій проводитимуться оффлайн чи онлайн конференції з використання АВ-приладів, що дозволить використовувати декілька екранів для відображення інформації/співрозмовників одразу, а також в один клік швидко можна трансформувати кімнату з офісної на релакс: перегляд улюблених фільмів чи передач, чи просто прослуховування музики.";

      const textDescribeEng =
      "The project uses only advanced technologies: control of natural and artificial lighting (roller shutters), climate control, and underfloor heating. The project features an integrated surveillance and audio system and a home theater. And the highlight is the office, which can be quickly transformed into a conference room where offline or online conferences will be held using AV devices, which will allow you to use several screens to display information/interlocutors at once, and in one click you can quickly transform the room from office to relaxation: watching your favorite movies or programs, or just listening to music.";

    return (
      <>
        <CurrentPrj
          name="Penthouse Diamond Hill"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default DiamondHill;
