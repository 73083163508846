import { PureComponent } from "react";
import cur_prj_1 from "../../../img/House in Classic Style Kiev/classic_1.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class ClassicHouse extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [cur_prj_1];

    const idUniq = 233;

    const textDescribe =
      "Уявіть квартиру, яка виконана у класичному стилі - врівноважена композиція, багаті аксесуари, чітке зонування кімнат на зони відпочинку, роботи та обідню зону. Разом з цим чудовим стилем використовуються найіннованіційніші розробки сучасності - домашня система автоматизація, котра тільки доповнює оселю. Автоматичне увімкнення світла по датчику руху, увімкнення улюбленого сценарія натисненням однієї кнопки, керування кліматом, теплою підлогою, шторами та встановлених у стінах аудіо-системи.";

      const textDescribeEng =
      "Imagine an apartment that is made in a classic style - a balanced composition, rich accessories, clear zoning of rooms into areas for rest, work, and dining. Along with this wonderful style, the most innovative developments of our time are used - a home automation system that only complements the home. Automatic switching on of lights by motion detector, activation of your favorite scenario at the touch of a button, climate control, underfloor heating, curtains and in-wall audio systems.";

    return (
      <>
        <CurrentPrj
          name="Classic house"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default ClassicHouse;
