import { PureComponent } from "react";
import cur_prj_1 from "../../../img/Pechersky Luxury Apartment/pechersk_1.jpg";
import cur_prj_2 from "../../../img/Pechersky Luxury Apartment/pechersk_2.jpg";
import cur_prj_3 from "../../../img/Pechersky Luxury Apartment/pechersk_3.jpg";
import cur_prj_4 from "../../../img/Pechersky Luxury Apartment/pechersk_4.jpg";
import cur_prj_5 from "../../../img/Pechersky Luxury Apartment/pechersk_5.jpg";
import cur_prj_6 from "../../../img/Pechersky Luxury Apartment/pechersk_6.jpg";
import cur_prj_7 from "../../../img/Pechersky Luxury Apartment/pechersk_7.jpg";
import cur_prj_8 from "../../../img/Pechersky Luxury Apartment/pechersk_8.png";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class PecherskyApart extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [
      cur_prj_1,
      cur_prj_2,
      cur_prj_3,
      cur_prj_4,
      cur_prj_5,
      cur_prj_6,
      cur_prj_7,
      cur_prj_8,
    ];

    const idUniq = 236;

    const textDescribe =
      "Встановлена система домашньої автоматизації реалізує завдання керування кліматом, шторами, освітленням, музикою. У систему управління Crestron інтегровано мультимедійну систему Sonos. Встановлено аварійний контроль для уникнення протікання та інших аварійних ситуацій. За допомогою інстальованих елементів управління «розумним будинком» власник в один дотик може змінювати світлові сцени в приміщенні або, використовуючи весь доступний функціонал для керування. Архітектор Baraban+ Design Studio.";

      const textDescribeEng =
      "The installed home automation system controls the climate, curtains, lighting, and music. The Sonos multimedia system is integrated into the Crestron control system. Emergency control was installed to avoid leaks and other emergencies. With the help of the installed smart home controls, the owner can change the light scenes in the room with one touch or using all the available control functionality. Architect Baraban+ Design Studio.";

    return (
      <>
        <CurrentPrj
          name="Pechersky art"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default PecherskyApart;
