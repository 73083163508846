import { PureComponent } from "react";
import styles from "./StudySolution.module.scss";
import Footer from "../../components/Footer/Footer";
import education_1 from "../../img/edu-1.jpg";
import education_2 from "../../img/edu-2.jpg";
import education_3 from "../../img/edu-3.jpg";
import education_4 from "../../img/edu-4.jpg";
import education_5 from "../../img/edu-5.jpg";
import { Helmet } from "react-helmet";

class StudySolution extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.engineRoot}>
        <Helmet>
          <title>{lang ? "Training solutions" : "Рішення для навчання"}</title>
          <meta
            name="description"
            content="У повній відповідності до вимог навчального процесу надається комплекс послуг, що оптимізує його функціонування | D8engineering | Crestron | Інтегрована система Crestron Fusion дозволяє дізнатися як краще розподіляти ІТ-бюджети | технологі], що дозволяють передавати нестиснуті 4К відео (60 Гц) | автоматизація процесу навчання"
          />
        </Helmet>
        <div className={styles.engineer_proj}>
          <div className={styles.education}>
            <div className={styles.education_dark_1}>
              <div className={styles.education_palochka}></div>
              <h1 className={styles.education_text_chapter}>
                {lang ? "TRAINING SOLUTIONS" : "РІШЕННЯ ДЛЯ НАВЧАННЯ"}
              </h1>
              <p className={styles.education_describe_chapter}>
                {lang
                  ? "In full compliance with the requirements of the educational process, a range of services is provided to optimize its functioning"
                  : "У повній відповідності до вимог навчального процесу надається комплекс послуг, що оптимізує його функціонування"}
              </p>
            </div>
            <div className={styles.education_light_1}>
              <img
                className={styles.education_foto_1}
                src={education_1}
                alt="edu education room"
              ></img>
              <div className={styles.education_paragraph_1}>
                {lang
                  ? "The integrated Crestron Fusion system allows you to learn how to better allocate IT budgets: combining usage data from the calendar planning system, building management system, video conferencing system, AV equipment, etc."
                  : "Інтегрована система Crestron Fusion дозволяє дізнатися як краще розподіляти ІТ-бюджети: об'єднання даних про використання із системи календарного планування, системи управління будівлею, системи відеоконференц-зв'язку, з АВ-обладнання тощо."}
                <br></br>
                <br></br>
                {lang
                  ? "Lectures are recorded and automatically sent to the server for processing, which allows you to quickly view, edit, and save video content for streaming in the future."
                  : "Лекції записуються та автоматично відправляються на сервер для обробки, що дозволяє швидко переглядати, монтувати та зберігати відеоконтент для потокової передачі надалі."}
                <br></br>
                <br></br>
                {lang
                  ? "You can get a report on the demand and use of premises and equipment: system and device usage, occupancy, availability of premises and equipment."
                  : "Можна отримати звіт про затребуваність та використання приміщень та обладнання: використання системи, пристроїв, заповнення приміщень, наявність приміщень та обладнання."}
              </div>
            </div>
            <div className={styles.education_dark_2}>
              <img
                className={styles.education_foto_2}
                src={education_2}
                alt="edu education solution computer"
              ></img>
              <div className={styles.education_paragraph_2}>
                {lang
                  ? "With Crestron Fusion, you don't need to be distracted by hardware issues, as there is a direct connection to the touch panel support service in the classroom, allowing you to report any problems to technicians at the touch of a button. The support staff can diagnose the problem using a webcam, troubleshoot user errors, reset settings, or change device parameters - and they don't need to be present to do it. It is also possible to notify students or teachers in case of any problems outside the classroom."
                  : "З використанням Crestron Fusion вам не потрібно відволікатися на вирішення проблем з обладнанням, адже є прямий зв'язок зі службою підтримки сенсорної панелі в аудиторії, що дає можливість повідомити технічних фахівців про будь-які проблеми одним натисканням кнопки. Співробітники служби підтримки можуть діагностувати проблему за допомогою веб-камери, усунути помилки користувачів, скинути налаштування або змінити параметри пристроїв - і для виконання всього цього їхня особиста присутність не знадобиться. Також передбачено оповіщення студентів чи викладачів у разі будь-яких проблем поза аудиторією."}
              </div>
            </div>
            <div className={styles.education_dark_3}>
              <img
                className={styles.education_foto_3}
                src={education_3}
                alt="edu education meeting room"
              ></img>
              <div className={styles.education_paragraph_3}>
                {lang ? "Provides simple wired or wireless connectivity for laptops and mobile devices to displays, providing a low-cost, personal device solution that is indispensable in classrooms or unplanned, quick meetings. Dynamic collaboration is enabled by technologies that allow uncompressed 4K video (60 Hz) to be streamed to multiple displays simultaneously with minimal latency. With one-button presentation playback and automatic signal source selection, using the equipment becomes simple and intuitive for teaching, sharing, teamwork, and conferencing" : "Реалізація простого дротового або бездротового підключення ноутбуків та мобільних пристроїв до дисплеїв, що є недорогим рішенням на базі особистих пристроїв, незамінно в аудиторіях або на незапланованих швидких зборах. Динамічна спільна робота забезпечується за допомогою технологій, що дозволяють передавати нестиснуті 4К відео (60 Гц) одночасно на кілька дисплеїв з мінімальною затримкою. Відтворення презентації натисканням однієї кнопки та автоматичний вибір джерела сигналу: використання обладнання стає простим та інтуїтивно зрозумілим для викладання, обміну матеріалами, колективної роботи та проведення конференцій."}
                
              </div>
            </div>
            <div className={styles.education_dark_4}>
              <img
                className={styles.education_foto_4}
                src={education_4}
                alt="edu meeting room Crestron light"
              ></img>
              <div className={styles.education_paragraph_4}>
                {lang ? "On a small budget, you'll be securely connected in no time to deploy and set up cloud-based meetings or conferences in any size meeting room with the Crestron Mercury. You can make Bluetooth calls, and professional 360° microphones and speakers are included to provide unrivaled audio quality for all participants. An additional advantage is the use of the AirMedia system: attendees can bring their own devices to the classroom and make presentations from them, which facilitates teamwork." : "За невеликий бюджет у найкоротші терміни ви безпечно підключитеся до мережі для розгортання та налаштування зустрічей або конференцій з використанням хмарних технологій у конференц-залах будь-якої величини за допомогою Crestron Mercury. Ви можете здійснювати дзвінки по Bluetooth, встановлюються професійні мікрофони 360° та динаміки, що забезпечать неперевершену якість звуку для всіх учасників. Додаткова перевага дає використання системи AirMedia: присутні можуть приносити на заняття свої пристрої та проводити презентації з них, що полегшує колективну роботу."}
                
              </div>
            </div>
            <div className={styles.education_dark_5}>
              <img
                className={styles.education_foto_5}
                src={education_5}
                alt="edu education study touchpanel touchscreen control smart automation"
              ></img>
              <div className={styles.education_paragraph_5}>
                {lang ? "With one touch, you can view the occupancy schedule and book a room with the touch panel. A solution with two screens for comparison and analysis is essential for training. Integrated control of AV equipment, lighting, curtains, and thermostats will create the most comfortable environment." : "В один дотик можна переглянути графік зайнятості та забронювати приміщення із сенсорною панеллю. Необхідним для навчання є рішення із двома екранами для порівняння та аналізу. Інтегроване керування АВ-обладнанням, освітленням, шторами та термостатами дозволить створити найбільш комфортну обстановку."} <br></br>
                <br></br>
                {lang ? "Distribution of AV signals without range restrictions, high quality at low bandwidth, use of existing standard network infrastructure, highest video quality, combination of stationary and mobile endpoints, no delays - all this will allow you to organize distance learning and increase the efficiency of communication between teachers and students." : "Розподіл АВ-сигналів без обмежень по дальності, висока якість за низької пропускної спроможності, використання існуючої стандартної мережевої інфраструктури, найвища якість відео, поєднання стаціонарних та мобільних кінцевих точок, відсутність затримок – все це дозволить організувати дистанційне навчання та підвищити ефективність спілкування викладачів та студентів."}
              </div>
            </div>
            <div className={styles.education_dark_6}>
              <div className={styles.education_paragraph_6}>
                {lang ? "Automation of the educational process will ensure dynamic and fruitful cooperation between teachers and students, allowing them to keep up with deadlines and easily exchange materials and ideas." : "Автоматизація навчального процесу забезпечить динамічну плідну співпрацю викладачів та студентів, дозволивши не прогаяти жодного терміну та з легкістю обмінюватися матеріалами та ідеями."}
                
              </div>
            </div>
          </div>
          <Footer lang={lang} />
        </div>
      </div>
    );
  }
}

export default StudySolution;
