import backArrow from "../../img/back_arrow_6W.svg";
import styles from "./NotFoundPage.module.scss";
import Button from "../../components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <div className={styles.root}>
        <Helmet>
          <title>ERROR 404</title>
          <meta
            name="description"
            content="Not found | D8engineering | d8.com.ua | системи автоматизації"
          />
           <link rel="canonical" href="https://d8.com.ua/404" />
        </Helmet>
        <h1 className={styles.title}>ERROR 404</h1>
        <h2 className={styles.subTitle}>
          {" "}
          Сторінка <span className={styles.path}>{pathname}</span> не знайдена{" "}
        </h2>
        <Button
          onClick={() => {
            navigate("/");
          }}
        >
          {" "}
          <img className={styles.backArrow} src={backArrow} alt="backsArrow" />
          Повернутися на головну
        </Button>
        <div className={styles.footer}>
          <Footer />{" "}
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
