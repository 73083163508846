import { PureComponent } from "react";
import cur_prj_1 from "../../../img/DTEK Academy/dtek_1.jpg";
import cur_prj_2 from "../../../img/DTEK Academy/dtek_2.jpg";
import cur_prj_3 from "../../../img/DTEK Academy/dtek_3.jpg";
import cur_prj_4 from "../../../img/DTEK Academy/dtek_4.jpg";
import cur_prj_5 from "../../../img/DTEK Academy/dtek_5.jpg";
import cur_prj_6 from "../../../img/DTEK Academy/dtek_6.jpg";
import cur_prj_7 from "../../../img/DTEK Academy/dtek_7.jpg";
import cur_prj_8 from "../../../img/DTEK Academy/dtek_8.jpg";
import cur_prj_9 from "../../../img/DTEK Academy/dtek_9.jpg";
import cur_prj_10 from "../../../img/DTEK Academy/dtek_10.jpg";
import cur_prj_11 from "../../../img/DTEK Academy/dtek_11.jpg";
import cur_prj_12 from "../../../img/DTEK Academy/dtek_12.jpg";
import cur_prj_13 from "../../../img/DTEK Academy/dtek_13.jpg";
import cur_prj_14 from "../../../img/DTEK Academy/dtek_14.jpg";
import cur_prj_15 from "../../../img/DTEK Academy/dtek_15.jpg";
import cur_prj_16 from "../../../img/DTEK Academy/dtek_16.jpg";
import cur_prj_17 from "../../../img/DTEK Academy/dtek_17.jpg";
import cur_prj_18 from "../../../img/DTEK Academy/dtek_18.jpg";
import cur_prj_19 from "../../../img/DTEK Academy/dtek_19.jpg";
import cur_prj_20 from "../../../img/DTEK Academy/dtek_20.jpg";
import cur_prj_21 from "../../../img/DTEK Academy/dtek_21.jpg";
import cur_prj_22 from "../../../img/DTEK Academy/dtek_22.jpg";
import cur_prj_23 from "../../../img/DTEK Academy/dtek_23.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class AcadDtek extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [
      cur_prj_1,
      cur_prj_2,
      cur_prj_3,
      cur_prj_4,
      cur_prj_5,
      cur_prj_6,
      cur_prj_7,
      cur_prj_8,
      cur_prj_9,
      cur_prj_10,
      cur_prj_11,
      cur_prj_12,
      cur_prj_13,
      cur_prj_14,
      cur_prj_15,
      cur_prj_16,
      cur_prj_17,
      cur_prj_18,
      cur_prj_19,
      cur_prj_20,
      cur_prj_21,
      cur_prj_22,
      cur_prj_23,
    ];

    const idUniq = 231;

    const textDescribe =
      "На всій площі Academy DTEK автоматизація приміщень зроблена з використанням стабільної, масштабованої та гнучкої системи Crestron. Управління аудіо, відео, шторами, екраном, проектором – комплексне використання цих функцій допоможе зробити зустрічі, конференц-зв'язки та презентації максимально комфортними. Реалізовано системи управління освітленням та кліматом, диспетчеризацією устаткування, що використовується в приміщеннях. Архітектор Сергій Махно.";

      const textDescribeEng =
      "Throughout the DTEK Academy, the premises are automated using the stable, scalable and flexible Crestron system. Audio, video, curtains, screen, projector control - the integrated use of these functions will help make meetings, conference calls and presentations as comfortable as possible. Lighting and climate control systems, as well as dispatching of equipment used in the premises, have been implemented. Architect Sergey Makhno.";

    return (
      <>
        <CurrentPrj
          name="Academy DTEK"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default AcadDtek;
