import { PureComponent } from "react";
import styles from "./PersonCinema.module.scss";
import Footer from "../../components/Footer/Footer";
import cinema_1 from "../../img/cinema-1.jpg";
import cinema_2 from "../../img/cinema-2.jpg";
import cinema_3 from "../../img/cinema-3.jpg";
import { Helmet } from "react-helmet";

class PersonCinema extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.engineRoot}>
        <Helmet>
          <title>{lang ? "Personal cinema" : "Персональні кінотеатри"}</title>
          <meta
            name="description"
            content="D8engineering: завдяки нашій співпраці з дизайнерами, наші роботи поєднують у собі якісно встановлені технічні рішення поряд з дизайном вашого персонального кінотеатру | Sonos | ScreenInnovation | Crestron"
          />
          <link rel="canonical" href="https://d8.com.ua/cinema" />
        </Helmet>
        <div className={styles.engineer_proj}>
          <div className={styles.cinema}>
            <div className={styles.cinema_dark_1}>
              <div className={styles.cinema_palochka}></div>
              <h1 className={styles.cinema_text_chapter}>
                {lang ? "CINEMA" : "КІНОТЕАТР"}
              </h1>
              <p className={styles.cinema_describe_chapter}>
                {lang
                  ? "Some people like action movies, others can't imagine an evening without another melodrama, and some people just need to watch comedies to abstract themselves from external problems... This list can go on forever, but we all have one thing in common: we love good movies. We go to cinemas because the sound and the big screen allow us to 'plunge headlong' into the epicenter of events, and afterwards this trip becomes a pleasant memory of a romantic or pleasant time with friends, and also gives us a common topic for conversation"
                  : "Комусь подобаються бойовики, хтось не уявляє собі вечора без чергової переглянутої мелодрами, а комусь просто необхідно дивитися комедії, щоб абстрагуватися від зовншініх проблем... Цей список можна продовжувати нескінченно довго, але нас усіх поєднує те, що ми любимо гарні фільми. Ми ходимо в кінотеатри, тому що там звучання і великий екран дозволяють нам 'з головою поринути' в епіцентр подій, а після цей похід стає приємним спогадом про романтичний або приємний з друзями час, а також ще й дарує спільну тему для розмов"}
              </p>
            </div>
            <div className={styles.cinema_light_1}>
              <img
                className={styles.cinema_foto_1}
                src={cinema_1}
                alt="cinema private house"
              ></img>
              <div className={styles.cinema_dark_palochka_1}></div>
              <div className={styles.cinema_article_1}>
                {lang
                  ? "INCREDIBLE IMAGE QUALITY"
                  : "НЕЙМОВІРНА ЯКІСТЬ ЗОБРАЖЕННЯ"}
              </div>
              <div className={styles.cinema_paragraph_1}>
                {lang
                  ? "The screens are part of a home theater system with a cinema-like aspect ratio, which is not supported by most modern TVs and projectors used in the home, and to get rid of black bars, a lens is used to project the image in such a way that you feel like you are in a good cinema, high lumen projectors are used, and screens that minimize the effect of daylight on image contrast."
                  : "Екрани є частиною системи домашнього кінотеатру із співвідношенням сторін як у кінотеатрах, що не підтримується більшістю сучасних телевізорів та проекторів, які використовуються вдома, і для порятунку від чорних смуг використовується лінза, яка дозволяє спроєктувати зображення таким чином, щоб ви відчули себе як у хорошому кінозалі, використовуються проектори з високим світловим потоком та екрани, що мінімізують вплив денного світла на контрастність зображення."}
              </div>
            </div>
            <div className={styles.cinema_light_2}>
              <img
                className={styles.cinema_foto_2}
                src={cinema_2}
                alt="cinema bw sound speakers"
              ></img>
              <div className={styles.cinema_dark_palochka_2}></div>
              <div className={styles.cinema_article_2}>
                {lang ? "HIGH-QUALITY SOUND" : "ВИСОКОЯКІСНИЙ ЗВУК"}
              </div>
              <div className={styles.cinema_paragraph_2}>
                {lang
                  ? "It is also worth noting that we will make sure that sound waves do not pass into neighboring rooms by designing sound and noise insulation specifically for your cinema."
                  : "Також слід зазначити, що ми подбаємо про те, щоб звукові хвилі не проходили в сусідні приміщення, шляхом проєктування спеціально під ваш кінозал звуко- та відброізоляції."}
                <br></br>
                <br></br>
                {lang
                  ? "Thanks to our cooperation with designers, our work combines well-installed technical solutions with the design of your personal cinema in accordance with your wishes and the advice of experts in the field."
                  : "Завдяки нашій співпраці з дизайнерами, наші роботи поєднують у собі якісно встановлені технічні рішення поряд з дизайном вашого персонального кінотеатру відповідно до ваших побажань та порад спеціалістів у цій галузі"}
              </div>
            </div>
            <div className={styles.cinema_light_3}>
              <img
                className={styles.cinema_foto_3}
                src={cinema_3}
                alt="cinema private room sound high"
              ></img>
              <div className={styles.cinema_paragraph_3}>
                {lang
                  ? "The use of high-quality acoustic equipment, design and installation of special floor, wall and ceiling structures with certain properties contribute to the full disclosure of all the features of sound systems in the listening room."
                  : "Використання високоякісної акустичної апаратури, проєктування та встановлення спеціальних конструкцій підлоги, стін, стелі з певними властивостями сприяють повному розкриттю всіх особливостей звукових систем у кімнаті прослуховування."}
              </div>
            </div>
            <div className={styles.cinema_light_4}>
              <div className={styles.cinema_paragraph_4}>
                {lang
                  ? "We have the highest requirements for comfort, so we created a personal cinema that combines aesthetic perfection and the most advanced technologies, whose work is coordinated, invisible and thought out to the smallest detail, which will make this place one of the most visited and favorite in the house, erasing the boundaries of the material world, leaving only your mind, sound and atmosphere that matches the chosen movie."
                  : " Ми виставляємо найвищі вимоги до комфорту, тому створений персональний кінотеатр, який поєднує в собі естетичну досконалість і найсучасніші технології, робота яких узгоджена, непомітна і продумана до дрібниць, що зробить це місце одним з найбільш відвідуваних та улюблених у будинку, стерши межі матеріального світу, залишивши тільки вашу свідомість, звучання та атмосферу, що відповідає обраному фільму."}
              </div>
            </div>
          </div>
          <Footer lang={lang} />
        </div>
      </div>
    );
  }
}

export default PersonCinema;
