import { PureComponent } from "react";
import cur_prj_1 from "../../../img/Mod Apartment/mod_1.jpg";
import cur_prj_2 from "../../../img/Mod Apartment/mod_2.jpg";
import cur_prj_3 from "../../../img/Mod Apartment/mod_3.jpg";
import cur_prj_4 from "../../../img/Mod Apartment/mod_4.jpg";
import cur_prj_5 from "../../../img/Mod Apartment/mod_5.jpg";
import cur_prj_6 from "../../../img/Mod Apartment/mod_6.jpg";
import cur_prj_7 from "../../../img/Mod Apartment/mod_7.jpg";
import cur_prj_8 from "../../../img/Mod Apartment/mod_8.jpg";
import cur_prj_9 from "../../../img/Mod Apartment/mod_9.jpg";
import cur_prj_10 from "../../../img/Mod Apartment/mod_10.jpg";
import cur_prj_11 from "../../../img/Mod Apartment/mod_11.jpg";
import cur_prj_12 from "../../../img/Mod Apartment/mod_12.jpg";
import cur_prj_13 from "../../../img/Mod Apartment/mod_13.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class ModPrj extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [
      cur_prj_1,
      cur_prj_2,
      cur_prj_3,
      cur_prj_4,
      cur_prj_5,
      cur_prj_6,
      cur_prj_7,
      cur_prj_8,
      cur_prj_9,
      cur_prj_10,
      cur_prj_11,
      cur_prj_12,
      cur_prj_13,
    ];

    const idUniq = 235;

    const textDescribe =
      "Даний проєкт, відзначений авторитетним виданням archdaily.com, є гарним прикладом балансу вартості та функціоналу системи домашньої автоматизації Crestron. У цьому проєкті реалізовані завдання управління освітленням, системою опалення та підігрівом підлоги, кондиціюванням та вентиляцією, зашторюванням, а також фоновим озвучуванням приміщень та домашнім кінотеатром. Керуючи 64-ма групами світла, серце (точніше мозок) «розумного будинку» - процесор Crestron створює в цьому проекті понад 30 різних сцен, включаючи у свої сценарії не тільки світильники, а й штори Lutron. Одна із родзинок проєкту – персональний кінотеатр JBL Synthesis з активним моторизованим екраном Screen Innovation, інноваційний матеріал якого дозволяє на ньому навіть вдень, не закриваючи штори, отримати яскраву, контрастну та насичену 180-дюймову картинку. Архітектор Сергій Махно.";

    const textDescribeEng =
      "This project, recognized by the reputable archdaily.com, is a good example of the balance of cost and functionality of the Crestron home automation system. The project includes control of lighting, heating and underfloor heating, air conditioning and ventilation, curtains, as well as background sound and home theater. Controlling 64 groups of light, the heart (or rather the brain) of the smart home, the Crestron processor creates more than 30 different scenes in this project, including not only lamps but also Lutron curtains in its scenarios. One of the highlights of the project is a JBL Synthesis personal cinema with an active motorized Screen Innovation screen, whose innovative material allows you to get a bright, contrasty and rich 180-inch picture even during the day without closing the curtains. Architect Sergey Makhno.";

    return (
      <>
        <CurrentPrj
          name="Modern project"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default ModPrj;
