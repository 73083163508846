import React from "react";
import styles from "./OurPartners.module.scss";
import partner_1 from "../../img/partner-1.png";
import partner_2 from "../../img/partner-2.png";
import partner_3 from "../../img/partner-3.png";
import partner_4 from "../../img/partner-4.png";
import partner_5 from "../../img/partner-5.png";
import partner_6 from "../../img/partner-6.png";
import partner_7 from "../../img/partner-7.jpg";
import partner_8 from "../../img/partner-8.png";
import partner_8_1 from "../../img/partner-8_1.png";
import partner_9 from "../../img/partner-9.png";
import partner_9_1 from "../../img/partner-9_1.png";
import partner_10 from "../../img/Domio_partners_logo.png";
import partner_10_1 from "../../img/partner-10_1.png";
import partner_11 from "../../img/partner-11.png";
import partner_12 from "../../img/avg_partners_logo.png";
import partner_14 from "../../img/MHP_partners_logo.png";
import partner_15 from "../../img/building_partners_logo.png";
import partner_16 from "../../img/osnova_partners_logo.png";

const OurPartners = (props) => {
  const { lang } = props;
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {lang ? "We are chosen" : "Нас обирають"}
      </div>

      <div className={styles.partner_grid}>
        <img
          className={styles.partner_foto_1}
          src={partner_2}
          alt="makhno"
        ></img>
        <img
          className={styles.partner_foto_2}
          src={partner_3}
          alt="baraban architecture"
        ></img>
        <img
          className={styles.partner_foto_3}
          src={partner_1}
          alt="yodesign"
        ></img>
        <img
          className={styles.partner_foto_4}
          src={partner_4}
          alt="bassa"
        ></img>
        <img
          className={styles.partner_foto_5}
          src={partner_5}
          alt="iqoza"
        ></img>
        <img
          className={styles.partner_foto_6}
          src={partner_11}
          alt="yana sokirko design"
        ></img>
        <img
          className={styles.partner_foto_7}
          src={partner_7}
          alt="mono architects"
        ></img>
        <img
          className={styles.partner_foto_8}
          src={partner_8}
          alt="lnz group"
        ></img>
        <img
          className={styles.partner_foto_8_1}
          src={partner_8_1}
          alt="lnz group color"
        ></img>
        <img className={styles.partner_foto_9} src={partner_9} alt="dtek"></img>
        <img
          className={styles.partner_foto_9_1}
          src={partner_9_1}
          alt="dtek color"
        ></img>
        <img
          className={styles.partner_foto_10}
          src={partner_10}
          alt="domio"
        ></img>
        <img
          className={styles.partner_foto_10_1}
          src={partner_10_1}
          alt="incube color"
        ></img>
        <img
          className={styles.partner_foto_11}
          src={partner_12}
          alt="partner avg group"
        ></img>
        <img
          className={styles.partner_foto_12}
          src={partner_6}
          alt="muza design"
        ></img>
        <img
          className={styles.partner_foto_14}
          src={partner_14}
          alt="partner mhp"
        ></img>
        <img
          className={styles.partner_foto_15}
          src={partner_15}
          alt="partners building evolution"
        ></img>
        <img
          className={styles.partner_foto_16}
          src={partner_16}
          alt="partner osnova solsif"
        ></img>
      </div>
    </div>
  );
};

export default OurPartners;
