import { PureComponent } from "react";
import styles from "./AudioSolution.module.scss";
import Footer from "../../components/Footer/Footer";
import audio_1 from "../../img/audio-1.jpg";
import audio_2 from "../../img/audio-2.jpg";
import audio_3 from "../../img/audio-3.jpg";
import audio_4 from "../../img/audio-4.jpg";
import { Helmet } from "react-helmet";

class AudioSolution extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.engineRoot}>
        <Helmet>
          <title>
            {lang
              ? "High-quality audio and multiroom"
              : "Високоякісне аудіо та мультирум"}
          </title>
          <meta
            name="description"
            content="Музика може надходити з інтернет-служб: Apple Music, Spotify, Google Play Music, PANDORA, tunein тощо | Sonos | D8engineering | Програвання різної музики та відео в окремих кімнатах, або одних і тих же у всьому будинку; звук, що відстежує ваш місце знаходження | Crestron"
          />
           <link rel="canonical" href="https://d8.com.ua/audiosolution" />
        </Helmet>
        <div className={styles.engineer_proj}>
          <div className={styles.music}>
            <div className={styles.music_dark_1}>
              <div className={styles.music_palochka}></div>
              <h1 className={styles.music_text_chapter}>
                {lang ? "AUDIO AND MULTIROOM" : "АУДІО ТА МУЛЬТИРУМ"}
              </h1>
              <p className={styles.music_describe_chapter}>
                {lang ? "Architects and designers work wonders with lighting, plumbing, air conditioning, appliances, furniture, and fabrics. But there's one element that developers often neglect, which is definitely not allowed to be done: sound" : "Архітектори та дизайнери творять дива з освітленням, сантехнікою, кондиціюванням повітря, побутовою технікою, меблями та тканиною. Але є один елемент, яким розробники часто нехтують, що робити точно не можна - це звук"}
                
              </p>
            </div>
            <div className={styles.music_dark_2}>
              <img
                className={styles.music_foto_1}
                src={audio_1}
                alt="audio"
              ></img>
              <div className={styles.music_paragraph_1}>
                {lang ? "Imagine music flowing through your rooms like light from windows or cool air from vents. That's what it feels like when speakers are built into your walls or ceilings, or even bookshelves, which is better for installing in a home that is under construction; or when wireless speakers are used, which are easier to use when the home is already fully furnished. It is also nice to be able to add music to the outdoors with outdoor speakers: in the yard, on the terrace, in the patio or by the pool." : "Уявіть музику, що тече через ваші кімнати, як світло з вікон чи прохолодне повітря з вентиляційних отворів. Це те, що здається, коли динаміки вбудовані у ваші стіни або стелі, або навіть у книжкові полиці, що краще для встановлення в будинку, який будується; або ж використовуються бездротові динаміки, які простіше використовувати, коли будинок вже повністю облаштований. Приємною є можливість додати звучання музики на вулиці за допомогою зовнішніх динаміків: у дворі, на терасі, в патіо або біля басейну."}
               
              </div>
            </div>
            <div className={styles.music_light_1}>
              <img
                className={styles.music_foto_2}
                src={audio_2}
                alt="audio interface"
              ></img>
              <div className={styles.music_paragraph_2}>
                {lang ? "Music can come from online services: Apple Music, Spotify, Google Play Music, PANDORA, tunein, etc.; from a computer or hard disk connected to your home network, from your smartphone." : "Музика може надходити з інтернет-служб: Apple Music, Spotify, Google Play Music, PANDORA, tunein тощо; з комп'ютера або жорсткого диска, підключеного до домашньої мережі, із смартфона."}
               
                <br></br>
                <br></br>
                {lang ? "It is possible to play different music and videos in separate rooms, or the same ones throughout the house; sound that tracks your location." : "Є можливість програвання різної музики та відео в окремих кімнатах, або одних і тих же у всьому будинку; звук, що відстежує ваш місце знаходження."}<br></br>
                <br></br>
                {lang ? "The undoubted advantage is the ease of control via smartphone, tablet, remote control or panel." : "Безперечною перевагою є легкість в керуванні за допомогою смартфона, планшета, пульта дистанційного керування чи панелі."}
              </div>
            </div>
            <div className={styles.music_dark_3}>
              <img
                className={styles.music_foto_3}
                src={audio_3}
                alt="audio panel Crestron"
              ></img>
              <div className={styles.music_light_palochka_1}></div>
              <div className={styles.music_head_paragraph_2}>
                {lang ? "THE ADVANTAGES OF THE SYSTEM ARE:" : "ПЕРЕВАГАМИ СИСТЕМИ Є:"}
                
              </div>
              <div className={styles.music_row_1} styles="display: table-row">
                <div className={styles.music_numeric_1}>01/</div>
                <div className={styles.music_paragraph_3_1}>
                  {lang ? "Different volume levels can be easily set in different listening areas" : "У різних зонах прослуховування легко встановлювати різні рівні гучності"}
                </div>
              </div>
              <div className={styles.music_row_2} styles="display: table-row">
                <div className={styles.music_numeric_2}>02/</div>
                <div className={styles.music_paragraph_3_2}>
                  {lang ? "Possibility of system expansion" : "Можливість розширення системи"}
                  
                </div>
              </div>
              <div className={styles.music_row_3} styles="display: table-row">
                <div className={styles.music_numeric_3}>03/</div>
                <div className={styles.music_paragraph_3_3}>
                  {lang ? "There is no need to install a set of equipment in each room: one is enough. Moreover, its location does not matter, it can be, for example, in the living room or in the back room" : "Немає необхідності встановлення комплекту апаратури у кожній кімнаті: достатньо одного. Причому його розташування немає ніякого значення, може перебувати, наприклад, у вітальні чи підсобці"}
                 
                </div>
              </div>
              <div className={styles.music_row_4} styles="display: table-row">
                <div className={styles.music_numeric_4}>04/</div>
                <div className={styles.music_paragraph_3_4}>
                  {lang ? "Watching movies or listening to music in any room: the convenience is that you will not miss a moment of the movie by moving to another room if necessary" : "Перегляд фільмів або прослуховування музики в будь-якій кімнаті: зручність полягає в тому, що ви не пропустите момент фільму, перейшовши за потребою до іншої кімнати"}
                 
                </div>
              </div>
            </div>
            <div className={styles.music_dark_4}>
              <img
                className={styles.music_foto_4}
                src={audio_4}
                alt="bw sound music speaker"
              ></img>
              <div className={styles.music_row_5} styles="display: table-row">
                <div className={styles.music_numeric_5}>05/</div>
                <div className={styles.music_paragraph_4_1}>
                  {lang ? "Mute the whole house with the touch of a button" : "Вимкнення звуку у всьому будинку натисканням однієї клавіші"}
                  
                </div>
              </div>
              <div className={styles.music_row_6} styles="display: table-row">
                <div className={styles.music_numeric_6}>06/</div>
                <div className={styles.music_paragraph_4_2}>
                  {lang ? "You won't miss a phone or doorbell because of loud music - alerts, speakerphone throughout the house" : "Ви не пропустите телефонний чи дверний дзвінок, через гучну музику - оповіщення, гучномовний зв'язок по всьому будинку"}
                 
                </div>
              </div>
              <div className={styles.music_row_7} styles="display: table-row">
                <div className={styles.music_numeric_7}>07/</div>
                <div className={styles.music_paragraph_4_3}>
                  {lang ? "Stylish and modern solutions - the first built-in presentation controllers with integrated control program and capacitive touch interface" : "Стильні та сучасні рішення - перші вбудовані презентаційні контролери з інтегрованою програмою управління та ємнісним сенсорним інтерфейсом"}
                  
                </div>
              </div>
            </div>
            <div className={styles.music_dark_5}>
              <div className={styles.music_paragraph_5}>
                {lang ? "Multiroom is an 'invisible system', 'sound from nowhere'! Only the things that make no sense to hide remain visible - displays (for example, plasma hanging on the wall) and elegant compact control panels of the multiroom system." : "Мультирум - це 'система-невидимка', 'звук нізвідки'! На видноті залишається тільки те, що ховати немає сенсу - дисплеї (скажімо, плазма, що висить на стіні) і витончені компактні панелі управління системи мультирум."}
               <br></br>
                <br></br>
                <br></br>
                {lang ? "It's a pleasure to manage the Multiroom system! You can control all connected zones from one place. In any room, you can connect to a shared archive of photos, movies or music, and even a video surveillance system." : "Керувати системою Мультирум - одна насолода! З одного місця можна контролювати усі підключені зони. У будь-якій кімнаті можна підключатись до загального архіву фотографій, фільмів або музики і навіть до системи відеоспотереження."}
              </div>
            </div>
          </div>
          <Footer lang={lang} />
        </div>
      </div>
    );
  }
}

export default AudioSolution;
