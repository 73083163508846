import { PureComponent } from "react";
import styles from "./ReservPwr.module.scss";
import Footer from "../../components/Footer/Footer";
import power_1 from "../../img/power-1.jpg";
import power_2 from "../../img/power-2.jpg";
import power_3 from "../../img/power-3.jpg";
import power_4 from "../../img/power-4.jpg";
import { Helmet } from "react-helmet";

class ReservPwr extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.engineRoot}>
        <Helmet>
          <title>{lang ? "Power redundancy" : "Резервація живлення"}</title>
          <meta
            name="description"
            content="Резервування енергії в домашніх умовах можна забезпечити шляхом використання спеціально призначеного для цього обладнання |  Бензиновий генератор | Дизельний генератор | Газовий генератор | Сонячна електростанція | Акумуляторні батареї"
          />
             <link rel="canonical" href="https://d8.com.ua/powerreservation" />
        </Helmet>
        <div className={styles.engineer_proj}>
          <div className={styles.power}>
            <div className={styles.power_dark_1}>
              <div className={styles.power_palochka}></div>
              <h1 className={styles.power_text_chapter}>
                {lang ? "POWER REDUNDANCY" : "РЕЗЕРВАЦІЯ ЖИВЛЕННЯ"}
              </h1>
              <p className={styles.power_describe_chapter}>
                {lang
                  ? "To protect your space from problems due to power outages or voltage drops below a critical value, you can take care of creating an appropriate emergency power supply system in advance"
                  : "Щоб убезпечити свій простір від проблем через перебої в подачі електрики або падіння напруги нижче критичного значення, заздалегідь можна подбати про створення відповідної системи аварійного електропостачання"}
              </p>
            </div>
            <div className={styles.power_light_1}>
              <div className={styles.power_dark_palochka_1}></div>
              <div className={styles.power_article_1}>
                {lang
                  ? "AN UNINTERRUPTIBLE BACKUP POWER SUPPLY SYSTEM FOR A HOME WILL COME IN HANDY IN SUCH CASES:"
                  : "СИСТЕМА БЕЗПЕРЕБІЙНОГО РЕЗЕРВНОГО ЕЛЕКТРОПОСТАЧАННЯ ДЛЯ БУДИНКУ СТАНЕ В НАГОДІ В ТАКИХ ВИПАДКАХ:"}
              </div>
              <div className={styles.power_row_1} styles="display: table-row">
                <div className={styles.power_numeric_1}>01/</div>
                <div className={styles.power_paragraph_1_1}>
                  {lang
                    ? "The power is often cut off in the house or apartment"
                    : "В будинку чи квартирі часто відключають живлення"}
                </div>
              </div>
              <div className={styles.power_row_2} styles="display: table-row">
                <div className={styles.power_numeric_2}>02/</div>
                <div className={styles.power_paragraph_1_2}>
                  {lang
                    ? "The power grid is periodically overvoltage"
                    : "В електромережі періодично виникає перенапруга"}
                </div>
              </div>
              <div className={styles.power_row_3} styles="display: table-row">
                <div className={styles.power_numeric_3}>03/</div>
                <div className={styles.power_paragraph_1_3}>
                  {lang
                    ? "Electricity of poor quality is supplied"
                    : "Подається електроенергія низької якості"}
                </div>
              </div>
              <div className={styles.power_row_4} styles="display: table-row">
                <div className={styles.power_numeric_4}>04/</div>
                <div className={styles.power_paragraph_1_4}>
                  {lang
                    ? "If there is an energy-dependent heating system that may fail due to a power outage"
                    : "Якщо присутня енергозалежна система опалювання, яка через відключення електрики може вийти з ладу"}
                </div>
              </div>
              <div className={styles.power_row_5} styles="display: table-row">
                <div className={styles.power_numeric_5}>05/</div>
                <div className={styles.power_paragraph_1_5}>
                  {lang
                    ? "In order to save electricity"
                    : "З метою економії електроенергії"}
                </div>
              </div>
              <div className={styles.power_row_6} styles="display: table-row">
                <div className={styles.power_numeric_6}>06/</div>
                <div className={styles.power_paragraph_1_6}>
                  {lang
                    ? "The desire to preserve the planet's ecology by using alternative sources of energy generation (e.g. solar stations and wind generators)"
                    : "Бажання збереження екології планети з використанням альтернативних джерел генерації енергії (наприклад сонячні станції та вітрові генератори)"}
                </div>
              </div>
              <img
                className={styles.power_foto_1}
                src={power_1}
                alt="pwr power reserv"
              ></img>
            </div>
            <div className={styles.power_light_2}>
              <div className={styles.power_paragraph_2_1}>
                {lang
                  ? "Energy backup at home can be provided by using equipment specially designed for this purpose."
                  : "Резервування енергії в домашніх умовах можна забезпечити шляхом використання спеціально призначеного для цього обладнання."}
              </div>
              <div className={styles.power_paragraph_2_2}>
                {lang
                  ? "The following can be used as a home standby power supply:"
                  : "Як джерела автономного живлення для дому можуть використовуватися:"}
              </div>
              <div className={styles.power_paragraph_2_3}>
                &#9679; &ensp;
                {lang ? "Gasoline generator" : "Бензиновий генератор"}
                <br></br>&#9679; &ensp;
                {lang ? "Diesel generator" : "Дизельний генератор"}
                <br></br>&#9679; &ensp;
                {lang ? "Gas generator" : "Газовий генератор"}
                <br></br>&#9679; &ensp;
                {lang ? "Solar power plant" : "Сонячна електростанція"}
                <br></br>&#9679; &ensp;
                {lang ? "Rechargeable batteries" : "Акумуляторні батареї"}
              </div>
              <img
                className={styles.power_foto_2}
                src={power_2}
                alt="power reservations battery"
              ></img>
            </div>
            <div className={styles.power_light_3}>
              <div className={styles.power_paragraph_3_1}>
                {lang
                  ? "UNINTERRUPTIBLE POWER SUPPLY (UPS)"
                  : "ДЖЕРЕЛО БЕЗПЕРЕБІЙНОГО ЖИВЛЕННЯ (ДБЖ)"}
              </div>
              <img
                className={styles.power_foto_3}
                src={power_3}
                alt="pwr power reservations sсheme"
              ></img>
              <div className={styles.power_paragraph_3_2}>
                {lang
                  ? "This device provides backup power supply using rechargeable batteries. The main advantages of an uninterruptible power supply are full automation, improved power quality, and environmental friendliness. The capacity of portable UPS can be easily increased as needed and expanded with additional batteries and external generators. It can be integrated directly with home power circuits."
                  : "Такий пристрій забезпечує резервне елетроживлення за допомогою акумуляторних батарей. Основні переваги джерела безперебійного живлення - повна автоматизація роботи, покращення якості електроживлення, екологічна чистота. Ємність портативних ДБЖ можна легко збільшувати за потребою та розширити за допомогою додаткових батарей, зовнішніх генераторів. Його можливо інтегрувати безпосередньо з домашніми ланцюгами живлення."}
              </div>
            </div>
            <div className={styles.power_dark_4}>
              <img
                className={styles.power_foto_4}
                src={power_4}
                alt="pwr alternative energy supply"
              ></img>
              <div className={styles.power_paragraph_4}>
                {lang
                  ? "The implementation of backup power can be realized through the use of alternative sources of electricity generation."
                  : "Реалізація резервного живлення може бути реалізована завдяки використанню альтернативних джерел генерації електроенергії."}
                <br></br>
                <br></br>{" "}
                {lang
                  ? "Such sources can be integrated into the overall automation system. This allows you to control and monitor the performance of alternative energy sources in real time."
                  : "Такі джерела можуть бути інтегровані в загальну систему автоматизації. Що дозволяє конролювати та моніторити показники джерел альтернативного забезпечення енергією в реальному часі."}
                <br></br>
                <br></br>{" "}
                {lang
                  ? "It is possible to provide an individual dashboard, which will allow you to view various indicators in the building systems. Current production and voltage on the system lines. Real-time monitoring of storage systems and their statuses."
                  : "Можливо передбачити індивідуальний дашборд, що дозволить дивитись різноманітні показники у системах будівлі. Поточне виробництво та напругу на лініях системи. Контроль за системами акумулювання та їх статусами в режимі реального часу."}
              </div>
            </div>
          </div>
          <Footer lang={lang} />
        </div>
      </div>
    );
  }
}

export default ReservPwr;
