import { BrowserRouter } from "react-router-dom";
import { Component } from "react";
import styles from "./App.module.scss";
import Header from "./components/Header/Header.js";
import ModalMenu from "./components/ModalMenu/index.js";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.js";
import AppRoutes from "./AppRoutes";
import { Helmet } from "react-helmet";

class App extends Component {
  state = {
    isOpenModal: false,
    isLang: false
  };

  toggleModal = (value) => {
    this.setState({ isOpenModal: value });
  };

  toggleLang = (value) => {
    this.setState({ isLang: !value });
  };
 

  render() {
    const { isOpenModal, isLang } = this.state;

    return (
      <div className={styles.App}>
        <BrowserRouter>
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Проєктування та автоматизація. Розумний будинок</title>
              <meta
                name="description"
                content="Проєктування та автоматизація від D8engineering | Резервація живлення | Енергоефективні рішення | Рішення для бізнесу |Персональні кінотеатри | Рішення для навчання | Аудіо та мультирум | Ми вважаємо, що розумний будинок лише тоді наш помічник, коли він простий у використанні та непомітний у роботі. | Crestron | Sonos"
              />
            </Helmet>
            <Header toggleModal={this.toggleModal} lang = {isLang} toggleLang = {this.toggleLang}/>
            <ScrollToTop />
            <ModalMenu isOpen={isOpenModal} toggleModal={this.toggleModal} lang = {isLang} toggleLang = {this.toggleLang}/>
            <section>
              <AppRoutes lang = {isLang} />
            </section>
          </>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
