import { Routes, Route} from 'react-router-dom';
import NotFoundPage from './pages/NotFoundPage';
import MainInfo from "./components/MainInfo/MainInfo";
import HomeAutomation from './pages/HomeAutomation/HomeAutomation';
import OurVision from './pages/OurVision/OurVision';
import FeedbackForm from './pages/FeedbackForm/FeedbackForm';
import EngineeringPrj from './pages/EngineeringPrj/EngineeringPrj';
import EnergyEfficient from './pages/EnergyEfficient/EnergyEfficient';
import PersonCinema from './pages/PersonCinema/PersonCinema';
import BusinessSolution from './pages/BusinessSolution/BusinessSolution';
import StudySolution from './pages/StudySolution/StudySolution';
import ReservPwr from './pages/ReservPwr/ReservPwr';
import AudioSolution from './pages/AudioSolution/AudioSolution';
import OurProjects from './pages/OurProjects/OurProjects';
import ModPrj from './pages/Projects/ModPrj/ModPrj';
import PenthouseSkyLine from './pages/Projects/PenthouseSkyLine/PenthouseSkyLine';
import MakhnoOffice from './pages/Projects/MakhnoOffice/MakhnoOffice';
import ShkrubHouse from './pages/Projects/ShkrubHouse/ShkrubHouse';
import AcadDtek from './pages/Projects/AcadDtek/AcadDtek';
import PecherskyApart from './pages/Projects/PeherskyApart/PecherskyApart';
import VatraHouse from './pages/Projects/VatraHouse/VatraHouse';
import ArtApart from './pages/Projects/ArtApart/ArtApart';
import ClassicHouse from './pages/Projects/ClassicHouse/ClassicHouse';
import PrivHs from './pages/Projects/PrivHs/PrivHs';
import FeedbackFormEn from './pages/FeedbackFormEn/FeedbackFormEn';
import DiamondHill from './pages/Projects/DiamondHill/DiamondHill';
import SkyLine112 from './pages/Projects/SkyLine112/SkyLine112';


const AppRoutes = (props) => {
    const {lang} = props;
    return (
        <Routes>
            <Route path='/' element={<MainInfo lang = {lang} />} />
            <Route path='/main' element={<MainInfo lang = {lang}/>} />
            <Route path='/homeautomation' element={<HomeAutomation lang = {lang}/>} />
            <Route path='/engineeringdesign' element={<EngineeringPrj lang = {lang}/>} />
            <Route path='/energyefficientdecision' element={<EnergyEfficient lang = {lang}/>} />
            <Route path='/cinema' element={<PersonCinema lang = {lang}/>} />
            <Route path='/businessSolution' element={<BusinessSolution lang = {lang}/>} />
            <Route path='/educationSolution' element={<StudySolution lang = {lang}/>} />
            <Route path='/powerreservation' element={<ReservPwr lang = {lang}/>} />
            <Route path='/audiosolution' element={<AudioSolution lang = {lang}/>} />
            <Route path='/ourvision' element={<OurVision lang = {lang}/>} />
            <Route path='/feedback' element={<FeedbackForm lang = {lang}/>} />
            <Route path='/feedback_en' element={<FeedbackFormEn lang = {lang}/>} />
            <Route path='/ourprojects' element={<OurProjects lang = {lang}/>} />
            <Route path='/services' element={<FeedbackForm lang = {lang}/>} />
            <Route path='/howWork' element={<FeedbackForm lang = {lang}/>} />
             <Route path='/project_c235' element={<ModPrj lang = {lang}/>} />
            <Route path='/project_c237' element={<PenthouseSkyLine lang = {lang}/>} />
            <Route path='/project_c234' element={<MakhnoOffice lang = {lang}/>} />
            <Route path='/project_c239' element={<ShkrubHouse lang = {lang}/>} />
            <Route path='/project_c231' element={<AcadDtek lang = {lang}/>} />
            <Route path='/project_c236' element={<PecherskyApart lang = {lang}/>} />
            <Route path='/project_c240' element={<VatraHouse lang = {lang}/>} />
            <Route path='/project_c232' element={<ArtApart lang = {lang}/>} />
            <Route path='/project_c233' element={<ClassicHouse lang = {lang}/>} />
            <Route path='/project_c238' element={<PrivHs lang = {lang}/>} />
            <Route path='/project_c241' element={<DiamondHill lang = {lang}/>} />
            <Route path='/project_c242' element={<SkyLine112 lang = {lang}/>} />
            <Route path='/404' element={<NotFoundPage />} />
            <Route path='*' element={<NotFoundPage />} />
        </Routes>
    )
}
export default AppRoutes;