import { PureComponent } from "react";
import styles from "./FeedbackFormEn.module.scss";
import { Formik, Form, Field } from "formik";
import CustomInput from "../../components/CustomInput";
import CustomInputText from "../../components/CustomInputText";
import Footer from "../../components/Footer/Footer";
import * as yup from "yup";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";

class FeedbackFormEn extends PureComponent {
  render() {
    const { lang } = this.props;

    const handleSubmit = (values, actions) => {
      console.log(values);
      console.log(actions);

      emailjs
        .send(
          "service_1yy9mi3",
          "template_t0v1vef",
          values,
          "8P9p3CZ72cbRrqDWh"
        )
        .then(
          (result) => {
            actions.resetForm();
            console.log(result.text);
            alert("Data sent!");
          },
          (error) => () => {
            alert("Oops, something went wrong. Try again!");
            console.log(error.text);
          }
        );
    };

    const initialValues = {
      name: "",
      email: "",
      phone: "",
      text: "",
    };

    const validationSchema = yup.object().shape({
      name: yup
        .string()
        .required("Field is required")
        .max(60, "The name should be less than 60 characters"),
        // .matches(/^[A-Za-z]+$/gi, "The name must contain only Latin characters"),
      email: yup
        .string()
        .email("Wrong format")
        .required("Field is required"),
      phone: yup
        .number()
        .min(10, "The number must contain more than 9 characters")
        .required("Field is required"),
      text: yup.string(),
    });

    return (
      <div className={styles.root}>
        <Helmet>
          <title>{lang ? "Contact us" :"Зв'язатися з нами"}</title>
          <meta
            name="description"
            content="Напиши нам | D8engineering  - проектування та системи автоматизації | розумний будинок | напиши нам своє бачення чи побажання щодо системи автоматизації | Crestron"
          />
        </Helmet>
        <div className={styles.headerImg}></div>
        <div className={styles.fbForm}>
          <h1 className={styles.fbForm_title}>{lang ? "Write to us" :"Напиши нам"}</h1>
          <hr />
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            id="feedbackForm"
          >
            {(props) => {
              console.log(props);
              return (
                <Form>
                  {/* <h3>FORMIK</h3> */}
                  <Field
                    type="text"
                    name="name"
                    placeholder="NAME"
                    label="Name"
                    component={CustomInput}
                  />
                  {/* <ErrorMessage name="name" render={msg => <span className='error'>{msg}</span>} /> */}

                  <Field
                    type="text"
                    name="email"
                    placeholder="EMAIL"
                    label="Email"
                    component={CustomInput}
                  />
                  {/* <ErrorMessage name="email" render={msg => <span className='error'>{msg}</span>} /> */}

                  <Field
                    type="text"
                    name="phone"
                    placeholder="PHONE"
                    label="Phone"
                    component={CustomInput}
                  />
                  {/* <ErrorMessage name="phone" render={msg => <span className='error'>{msg}</span>} /> */}

                  <Field
                    type="text"
                    name="text"
                    placeholder="TEXT"
                    label="text"
                    component={CustomInputText}
                  />

                  <button type="submit">{lang ? "SEND" :"ВІДПРАВИТИ"}</button>
                </Form>
              );
            }}
          </Formik>
        </div>

        <Footer lang={lang} />
      </div>
    );
  }
}

export default FeedbackFormEn;
