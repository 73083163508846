import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

const colorsMap = {
    blue: styles.blue,
    red: styles.red,
}

class Button extends PureComponent {
    state = {
        isPressed: false,
    }

    render() {
        const {children, color, onClick, className, type} = this.props;
        const {isPressed} = this.state;
        const pressedStyle = isPressed ? styles.pressed : '';

        return (
            <button
                onMouseDown={() => this.setState({isPressed: true})}
                onMouseUp={() => this.setState({isPressed: false})}
                onClick={onClick}
                className={`${styles.btn} ${pressedStyle} ${colorsMap[color]} ${className}`}
                type={type}
            >
                {children}
            </button>
        )
    }

}

Button.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    onClick: PropTypes.func,
    color: PropTypes.oneOf(['blue', 'red']),
    className: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

Button.defaultProps = {
    onClick: () => {
    },
    color: 'blue',
    className: '',
    type: 'button',
};

export default Button;