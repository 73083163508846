import { PureComponent } from "react";
import styles from "./Modal.module.scss";
import ButtonMenu from "../ButtonMenu/ButtonMenu.js";
import YouTube from "../../img/youtube_icon.png";
import Instagram from "../../img/instagram_icon.png";
import Linkedin from "../../img/linkedin_icon.png";
import { NavLink } from "react-router-dom";
import ButtonMenuAnchor from "../ButtonMenuAnchor/ButtonMenuAnchor";

class ModalMenu extends PureComponent {
  render() {
    const { isOpen, toggleModal, lang, toggleLang } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <div className={styles.root}>
        <div className={styles.content}>
          <div
            onClick={() => toggleModal(false)}
            className={styles.closeWrapper}
          >
            <span className={styles.lineFirst}></span>
            <span className={styles.lineSecond}></span>
            <span className={styles.lineThird}></span>
          </div>
          <div className={styles.menuLanguage} onClick={() => toggleLang(lang)}>
            {" "}
            {lang ? "UA" : "EN"}
          </div>
          <div className={styles.nameWrapper}>MENU</div>
          <div className={styles.buttonContainer}>
            <ButtonMenu linkGo={""} onClick={() => toggleModal(false)}>
            {lang ? "Main" : "Головна"}
            </ButtonMenu>
            <ButtonMenuAnchor
              linkGo={"aboutUs"}
              onClick={() => toggleModal(false)}
            >
                {lang ? "About us" : "Про нас"}
             
            </ButtonMenuAnchor>
            <ButtonMenu linkGo={"ourVision"} onClick={() => toggleModal(false)}>
              {" "}
              {lang ? "Our vision" : "Наше бачення"}
             
            </ButtonMenu>
            <ButtonMenu
              linkGo={"ourprojects"}
              onClick={() => toggleModal(false)}
            >
                {lang ? "Our projects" : "Наші проєкти"}
             
            </ButtonMenu>
            <ButtonMenuAnchor
              linkGo={"purpose"}
              onClick={() => toggleModal(false)}
            >
                {lang ? "We offer" : "Ми пропонуємо"}
           
            </ButtonMenuAnchor>
            <ButtonMenuAnchor
              linkGo={"describe"}
              onClick={() => toggleModal(false)}
            >
                {lang ? "Why us?" : "Чому саме ми?"}
          
            </ButtonMenuAnchor>
            <ButtonMenuAnchor
              linkGo={"footer"}
              onClick={() => toggleModal(false)}
            >
                {lang ? "Contacts" : "Контакти"}
        
            </ButtonMenuAnchor>
          </div>

          <div className={styles.socialIconsContainer}>
            <NavLink
              to="https://www.youtube.com/@D8Engineering"
              target="_blank"
            >
              <img
                src={YouTube}
                alt="YouTube_Icon"
                className={styles.firstImg}
              ></img>
            </NavLink>
            <NavLink
              to="https://www.instagram.com/d8_engineering/"
              target="_blank"
            >
              <img
                src={Instagram}
                alt="Instagram_Icon"
                className={styles.secondImg}
              ></img>
            </NavLink>
            <NavLink
              to="https://www.linkedin.com/company/d8-engineering"
              target="_blank"
            >
              <img
                src={Linkedin}
                alt="Linkedin_Icon"
                className={styles.thirdImg}
              ></img>
            </NavLink>
          </div>
        </div>
        <div onClick={() => toggleModal(false)} className={styles.background} />
      </div>
    );
  }
}

export default ModalMenu;
