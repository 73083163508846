import { PureComponent } from "react";
import styles from "./LetsTell.module.scss";
import { NavLink } from "react-router-dom";
import TG from "../../img/telegram_contact_icon_png.png";
import arrowtoTop from "../../img/arrow_to_above_icon.png";

class LetsTell extends PureComponent {
  scrollto = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { lang } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.title}>
          {lang ? "Write to us" : "Напиши нам"}
        </div>
        <div className={styles.subTitle}>
          {lang
            ? "Let's talk about our future"
            : "Давайте поговоримо про наше майбутнє"}
        </div>
        <hr className={styles.line} />
        <div className={styles.btnConnect}>
          {lang ? (
            <NavLink
              to={"/feedback_en"}
              style={({ isActive, isPending }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isPending ? "darkgrey" : "black",
                  textDecoration: "none",
                };
              }}
            >
              <button>Contact us</button>
            </NavLink>
          ) : (
            <NavLink
              to={"/feedback"}
              style={({ isActive, isPending }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isPending ? "darkgrey" : "black",
                  textDecoration: "none",
                };
              }}
            >
              <button>ЗВ'ЯЗАТИСЬ З НАМИ</button>
            </NavLink>
          )}

          <div className={styles.tgContact}>
            <NavLink
              to={"https://t.me/DavydovRoman"}
              target="_blank"
              style={({ isActive, isPending }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isPending ? "darkgrey" : "black",
                  textDecoration: "none",
                };
              }}
            >
              <img
                className={styles.tgImg}
                src={TG}
                alt="telegram link social"
              ></img>
            </NavLink>
          </div>
        </div>
        <button className={styles.scrollToTop} onClick={() => this.scrollto()}>
          <img
            className={styles.arrowTop}
            src={arrowtoTop}
            alt="arrow scroll"
          ></img>
        </button>
      </div>
    );
  }
}

export default LetsTell;
