import { useState, useEffect } from "react";
import styles from "./CountCard.module.scss";
import jsonText from "./whyWeText.json";

const CountCard = (props) => {
  const { lang } = props;
  const [num, setNum] = useState(1);
  const [text, setText] = useState(jsonText[0].text);
  const [chng, setChng] = useState(0);
  const [textEn, setTextEn] = useState(jsonText[0].textEn);

  useEffect(() => {
    var elem = document.getElementById("mainText");
    var btnPrev = document.getElementById("btnPrev");
    var btnPrevMin = document.getElementById("btnPrevMin");
    var btnNextMax = document.getElementById("btnNextMax");
    var btnNextMin = document.getElementById("btnNextMin");
    btnNextMax.onclick = function () {
      elem.classList.add(`${styles.animationText}`);
    };
    btnNextMin.onclick = function () {
      elem.classList.add(`${styles.animationText}`);
    };
    btnPrev.onclick = function () {
      elem.classList.add(`${styles.animationText}`);
    };
    btnPrevMin.onclick = function () {
      elem.classList.add(`${styles.animationText}`);
    };

    const timeout = setTimeout(() => {
      elem.classList.remove(`${styles.animationText}`);
    }, 600);

    return () => clearTimeout(timeout);
  }, [chng]);

  const funcSetNext = (num) => {
    setNum((num) => {
      if (num < 5) {
        num = num + 1;
      }
      return num;
    });
  };

  const funcSetPrev = (num) => {
    setNum((num) => {
      if (num > 1) {
        num = num - 1;
      }
      return num;
    });
  };

  const funcSetTextNext = (num) => {
    setText((text) => {
      text = jsonText[num].text;

      return text;
    });
  };

  const funcSetTextNextEn = (num) => {
    setTextEn((textEn) => {
      textEn = jsonText[num].textEn;

      return textEn;
    });
  };

  const funcSetTextPrev = (num) => {
    setText((text) => {
      text = jsonText[num - 2].text;

      return text;
    });
  };

  const funcSetTextPrevEn = (num) => {
    setTextEn((textEn) => {
      textEn = jsonText[num - 2].textEn;

      return textEn;
    });
  };

  const funcSetChng = () => {
    setChng((chng) => {
      chng = chng + 1;
      return chng;
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>{lang ? "Why us?" : "Чому саме ми?"}</div>
      <button
        className={num === 1 ? `${styles.noVisible}` : `${styles.btnPrev}`}
        onClick={() => {
          funcSetPrev(num);
          funcSetChng();
          funcSetTextPrev(num);
          funcSetTextPrevEn(num);
        }}
        id="btnPrev"
      >
        <span
          className={`${
            styles.btnNav_prev__symb
          } ${"material-symbols-outlined"}`}
        >
          arrow_back_ios
        </span>
      </button>
      <div className={styles.counter}>
        0{num}
        <hr className={styles.counter_line}></hr>
      </div>
      <div
        className={
          num > 1
            ? `${styles.text} ${styles.marginText} ${styles.animationText}`
            : `${styles.text} ${styles.animationText}`
        }
        id="mainText"
      >
        {lang ? `${textEn}` : `${text}`}
      </div>
      <div className={styles.btnNav}>
        <button
          className={
            num === 1 ? `${styles.noVisible}` : `${styles.btnNav_prev}`
          }
          onClick={() => {
            funcSetPrev(num);
            funcSetChng();
            funcSetTextPrev(num);
            funcSetTextPrevEn(num);
          }}
          id="btnPrevMin"
        >
          <span
            className={`${
              styles.btnNav_prev__symb
            } ${"material-symbols-outlined"}`}
          >
            arrow_back_ios
          </span>
        </button>
        <button
          className={
            num === 5 ? `${styles.noVisible}` : `${styles.btnNav_next}`
          }
          onClick={() => {
            funcSetNext(num);
            funcSetChng();
            funcSetTextNext(num);
            funcSetTextNextEn(num);
          }}
          id="btnNextMin"
        >
          <span
            className={`${
              styles.btnNav_next__symb
            } ${"material-symbols-outlined"}`}
          >
            arrow_forward_ios
          </span>
        </button>
      </div>

      <button
        className={num === 5 ? `${styles.noVisible}` : `${styles.btnNext}`}
        onClick={() => {
          funcSetNext(num);
          funcSetChng();
          funcSetTextNext(num);
          funcSetTextNextEn(num);
        }}
        id="btnNextMax"
      >
        <span
          className={`${
            styles.btnNav_next__symb
          } ${"material-symbols-outlined"}`}
        >
          arrow_forward_ios
        </span>
      </button>
    </div>
  );
};

export default CountCard;
