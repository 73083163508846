import { PureComponent } from "react";
import cur_prj_1 from "../../../img/Art Apartment/art_1.jpg";
import cur_prj_2 from "../../../img/Art Apartment/art_2.jpg";
import cur_prj_3 from "../../../img/Art Apartment/art_3.jpg";
import cur_prj_4 from "../../../img/Art Apartment/art_4.jpg";
import cur_prj_5 from "../../../img/Art Apartment/art_5.jpg";
import cur_prj_6 from "../../../img/Art Apartment/art_6.jpg";
import cur_prj_7 from "../../../img/Art Apartment/art_7.jpg";
import cur_prj_8 from "../../../img/Art Apartment/art_8.jpg";
import cur_prj_9 from "../../../img/Art Apartment/art_9.jpg";
import cur_prj_10 from "../../../img/Art Apartment/art_10.jpg";
import cur_prj_11 from "../../../img/Art Apartment/art_11.jpg";
import cur_prj_12 from "../../../img/Art Apartment/art_12.jpg";
import cur_prj_13 from "../../../img/Art Apartment/art_13.jpg";
import cur_prj_14 from "../../../img/Art Apartment/art_14.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class ArtApart extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [
      cur_prj_1,
      cur_prj_2,
      cur_prj_3,
      cur_prj_4,
      cur_prj_5,
      cur_prj_6,
      cur_prj_7,
      cur_prj_8,
      cur_prj_9,
      cur_prj_10,
      cur_prj_11,
      cur_prj_12,
      cur_prj_13,
      cur_prj_14,
    ];

    const idUniq = 232;

    const textDescribe =
      "«Розумний будинок» реалізує завдання управління освітленням, шторами, кліматом, музикою, враховуючи всі вподобання власника та миттєво виконує його побажання щодо стану приміщень для комфортної та ефективної роботи або розслаблення, і водночас є системою, яка надійна, гнучка та непомітна в роботі. Особливістю цього об'єкта і те, що у систему управління Crestron інтегрована мультимедійна система Sonos. Для уникнення протікання та інших неприємних ситуацій встановлено аварійний контроль, що дозволить власнику постійно бути впевненим, що з його квартирою все гаразд. Архітектор Сергій Махно.";

      const textDescribeEng =
      "The smart home controls lighting, curtains, climate, and music, taking into account all the owner's preferences and instantly fulfilling his or her wishes regarding the condition of the premises for comfortable and efficient work or relaxation, and at the same time is a system that is reliable, flexible, and invisible in operation. A special feature of this facility is that the Sonos multimedia system is integrated into the Crestron control system. To avoid leaks and other unpleasant situations, an emergency control system has been installed, which will allow the owner to be sure that everything is in order with his apartment. Architect Sergey Makhno.";

    return (
      <>
        <CurrentPrj
          name="Art apartment"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default ArtApart;
