// import { PureComponent, useRef, useState, useEffect } from "react";
import { useMemo } from "react";
import styles from "./VideoCarousel.module.scss";
import Carroussel from "./Carroussel/Carroussel.js";
import { v4 as uuidv4 } from "uuid";
import YoutubeEmbed from "./YoutubeEmbed/YoutubeEmbed";

const expensiveCalculation = (num) => {
  const pageWidth = document.documentElement.scrollWidth;
  // console.log(pageWidth);

  if (pageWidth < 768) {
    num = "300px";
  } else if (pageWidth >= 768 && pageWidth < 1919) {
    num = "500px";
  } else {
    num = "900px";
  }
  return num;
};

const expensiveCalcHeight = (val) => {
  const pageWidth = document.documentElement.scrollWidth;

  if (pageWidth < 480) {
    val = "240px";
  } else if (pageWidth >= 480 && pageWidth < 768) {
    val = "240px";//240
  } else if (pageWidth >= 768 && pageWidth < 1280) {
    val = "315px";//315
  }  else if (pageWidth >= 1280 && pageWidth < 1920) {
    val = "480px";//480
  } else {
    val = "720px";//720
  }
  return val;
};

const expensiveCalcWidth = (val) => {
  const pageWidth = document.documentElement.scrollWidth;

  if (pageWidth < 420) {
    val = "426px";
  } else if (pageWidth >= 420 && pageWidth < 768) {
    val = "426px";//426
  } else if (pageWidth >= 768 && pageWidth < 1280) {
    val = "560px";//560
  }  else if (pageWidth >= 1280 && pageWidth < 1920) {
    val = "854px";//854
  } else {
    val = "1280px";//1280
  }
  return val;
};


export default function VideoCarousel() {
  let count = "300px";
  let height = "426px";
  let width = "240px";
  const calculation = useMemo(() => expensiveCalculation(count), [count]);
  const calcHeight = useMemo(() => expensiveCalcHeight(height), [height]);
  const calcWidth = useMemo(() => expensiveCalcWidth(width), [width]);

  const cards = [
    {
      key: uuidv4(),
      content: <YoutubeEmbed embedId="DEIfkRloxM8" height={calcHeight} width={calcWidth} />,
    },
    {
      key: uuidv4(),
      content: <YoutubeEmbed embedId="SXa9YnxcEOc" height={calcHeight} width={calcWidth} />,
    },
    {
      key: uuidv4(),
      content: <YoutubeEmbed embedId="W_QkZ6WMgpk" height={calcHeight} width={calcWidth} />,
    },
    {
      key: uuidv4(),
      content: <YoutubeEmbed embedId="T4gp5vYlMEE" height={calcHeight} width={calcWidth} />,
    },
    {
      key: uuidv4(),
      content: <YoutubeEmbed embedId="QSgsL_scvxE" height={calcHeight} width={calcWidth} />,
    },
    {
      key: uuidv4(),
      content: <YoutubeEmbed embedId="u_PnnznCpy0" height={calcHeight} width={calcWidth} />,
    },
    {
      key: uuidv4(),
      content: <YoutubeEmbed embedId="00iPX8JHbw0" height={calcHeight} width={calcWidth} />,
    },
    {
      key: uuidv4(),
      content: <YoutubeEmbed embedId="lGaVYG2Sgcc" height={calcHeight} width={calcWidth} />,
    },
  ];

  return (
    <div className={styles.root}>
      <Carroussel
        cards={cards}
        height={calculation}
        width="90%"
        margin="0 auto"
        offset={2}
        showArrows={true}
      />
    </div>
  );
}

// export default VideoCarousel;
