import { PureComponent } from "react";
import cur_prj_1 from "../../../img/Private house in Kiev/prvhouse_1.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class PrivHs extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [cur_prj_1];

    const idUniq = 238;

    const textDescribe =
      "Даний проєкт з системою автоматизації оснащений всіма важливими аспектами, котрі ми використовуємо у повсякденому житті - освітлення, клімат, штори, тепла підлога та аудіо. Цікавинкою даного проєкту є міні-кінотеатр, який ви можете використовувати не лише у якості перегляду фільму, а й відтворювати музику для створення бажаного настрою, чи грати у відеоігри на великому екрані.";

      const textDescribeEng =
      "This project with an automation system is equipped with all the important aspects that we use in our daily lives - lighting, climate, curtains, underfloor heating, and audio. The highlight of this project is the mini-cinema, which you can use not only to watch a movie, but also to play music to create the desired mood or play video games on the big screen.";

    return (
      <>
        <CurrentPrj
          name="Private house"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default PrivHs;
