import { PureComponent } from "react";
import styles from "./HomeAutomation.module.scss";
import Footer from "../../components/Footer/Footer";
import automation_1 from "../../img/automation-1.jpg";
import automation_2 from "../../img/automation-2.jpg";
import automation_3 from "../../img/automation-3.jpg";
import automation_4 from "../../img/automation-4.jpg";
import { Helmet } from "react-helmet";

class HomeAutomation extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.automationRoot}>
        <Helmet>
          <title>{lang ? "Home automation" : "Домашня автоматизація"}</title>
          <meta
            name="description"
            content="Домашня автоматизація - це безмежні можливості автоматизації всіх інженерних систем у будинку, інтегрування інтелектуальних систем які можуть бути об'єднані в єдиний комплекс | D8engineering | УПРАВЛІННЯ ОСВІТЛЕННЯМ |КЕРУВАННЯ МІКРОКЛІМАТОМ | АУДІО ТА МУЛЬТИРУМ | ПЕРСОНАЛЬНИЙ КІНОТЕАТР | ОХОРОННІ СИСТЕМИ | СИСТЕМИ ДОСТУПУ | ЕНЕРГОЕФЕКТИВНІ СИСТЕМИ | УПРАВЛІННЯ ШТОРАМИ | ПОЛИВ РОСЛИН | СИСТЕМИ МОНІТОРИНГУ ЯКОСТІ ПОВІТРЯ ТА CO2 | Crestron"
          />
          <link rel="canonical" href="https://d8.com.ua/homeautomation" />
        </Helmet>
        <div className={styles.automation}>
          <div className={styles.automation_dark_1}>
            <div className={styles.automation_palochka}></div>
            <h1 className={styles.automation_text_chapter}>
              {lang ? "HOME AUTOMATION" : "ДОМАШНЯ АВТОМАТИЗАЦІЯ"}
            </h1>
            <p className={styles.automation_describe_chapter}>
              {lang
                ? "Home automation means unlimited possibilities for automating all engineering systems in the house, integrating intelligent systems that can be combined into a single complex or separately: it all depends on your wishes."
                : "Домашня автоматизація - це безмежні можливості автоматизації всіх інженерних систем у будинку, інтегрування інтелектуальних систем які можуть бути об'єднані в єдиний комплекс, так і окремо: все залежить від ваших бажань."}
            </p>
          </div>
          <div className={styles.automation_light_1}>
            <img
              className={styles.automation_foto_1}
              src={automation_1}
              alt="automation house smarthome"
            ></img>
            <div className={styles.automation_paragraph_1}>
              &#9679; &ensp;
              {lang ? "LIGHTING CONTROL" : "Управління освітленням"}
              <br></br>&#9679; &ensp;
              {lang ? "MICROCLIMATE CONTROL" : "Керування мікрокліматом"}
              <br></br>&#9679; &ensp;
              {lang ? "AUDIO AND MULTIROOM CONTROL" : "Аудіо та мультирум"}
              <br></br>&#9679; &ensp;
              {lang ? "PERSONAL CINEMA" : "Персональний кінотеатр"}
              <br></br>&#9679;&ensp;
              {lang ? "  SECURITY SYSTEMS" : "  Охоронні системи"}
              <br></br>&#9679; &ensp;
              {lang ? "ACCESS SYSTEMS" : "Системи доступу"}
              <br></br>&#9679; &ensp;
              {lang ? "ENERGY EFFICIENT SYSTEMS" : "Енергоефективні системи"}
              <br></br>&#9679;&ensp;
              {lang ? "  CURTAIN CONTROL" : "  Управління шторами"}
              <br></br>&#9679; &ensp;{lang ? "WATERING PLANTS" : "Полив рослин"}
              <br></br>&#9679; &ensp;
              {lang
                ? "AIR QUALITY AND CO2 MONITORING SYSTEMS"
                : "Системи моніторингу якості повітря та CO2"}
            </div>
          </div>
          <div className={styles.automation_dark_2}>
            <img
              className={styles.automation_foto_2}
              src={automation_2}
              alt="automation house speaker security"
            ></img>
            <div className={styles.automation_light_palochka_1}></div>
            <div className={styles.automation_head_paragraph_2}>
              {lang
                ? "THE CLEAR ADVANTAGES OF AN INTEGRATED SYSTEM ARE:"
                : "ЯВНИМИ ПЕРЕВАГАМИ ІНТЕГРОВАНОЇ СИСТЕМИ Є:"}
            </div>
            <div
              className={styles.automation_row_1}
              styles="display: table-row"
            >
              <div className={styles.automation_numeric_1}>01/</div>
              <div className={styles.automation_paragraph_2_1}>
                {lang
                  ? "Reliability: safety and predictability of the system"
                  : "Надійність: безпека та передбачуваність роботи системи"}
              </div>
            </div>
            <div
              className={styles.automation_row_2}
              styles="display: table-row"
            >
              <div className={styles.automation_numeric_2}>02/</div>
              <div className={styles.automation_paragraph_2_2}>
                {lang
                  ? "Invisibility in work and everyday life"
                  : "Непомітність у роботі та повсякденному житті"}
              </div>
            </div>
            <div
              className={styles.automation_row_3}
              styles="display: table-row"
            >
              <div className={styles.automation_numeric_3}>03/</div>
              <div className={styles.automation_paragraph_2_3}>
                {lang
                  ? "Analysis of resource utilization, emergency control, energy management"
                  : "Аналіз використання ресурсів, антиаварійний контроль,енергоменеджмент"}
              </div>
            </div>
            <div
              className={styles.automation_row_4}
              styles="display: table-row"
            >
              <div className={styles.automation_numeric_4}>04/</div>
              <div className={styles.automation_paragraph_2_4}>
                {lang
                  ? "Your home and surrounding areas will always be under control, 24 hours a day, in real time, from anywhere in the world"
                  : " Ваш будинок та прилеглі території завжди будть під контролем, 24 години на добу, в реальному часі, з будь-якої точки світу"}
              </div>
            </div>
            <div
              className={styles.automation_row_5}
              styles="display: table-row"
            >
              <div className={styles.automation_numeric_5}>05/</div>
              <div className={styles.automation_paragraph_2_5}>
                {lang
                  ? "Manage all devices and equipment in one system"
                  : "Керування всіма пристроями та обладнанням в одній системі"}
              </div>
            </div>
          </div>
          <div className={styles.automation_dark_3}>
            <img
              className={styles.automation_foto_3}
              src={automation_3}
              alt="automation smart home keypad buttons"
            ></img>
            <div
              className={styles.automation_row_6}
              styles="display: table-row"
            >
              <div className={styles.automation_numeric_6}>06/</div>
              <div className={styles.automation_paragraph_3_1}>
                {lang
                  ? "Practical and easy to operate"
                  : "Практичність та легкість в управлінні"}
              </div>
            </div>
            <div
              className={styles.automation_row_7}
              styles="display: table-row"
            >
              <div className={styles.automation_numeric_7}>07/</div>
              <div className={styles.automation_paragraph_3_2}>
                {lang
                  ? "Intuitive control panel interfaces and modern design will not only fit perfectly into your interior, but will also become an integral part of it"
                  : "Інтуїтивно зрозумілі інтерфейси панелей управління, їхній сучасний дизайн не тільки ідеально впишеться у ваш інтер'єр, але й стане його невід'ємним атрибутом"}
              </div>
            </div>
            <div
              className={styles.automation_row_8}
              styles="display: table-row"
            >
              <div className={styles.automation_numeric_8}>08/</div>
              <div className={styles.automation_paragraph_3_3}>
                {lang
                  ? "Crestron™ software is a first-class development environment for creating complete and flexible control of automation systems. As a result, almost any device can be integrated into it with support for many communication protocols and standards"
                  : " Програмне забезпечення Crestron™ - це середовище розробки першого класу для створення повного та гнучкого керування системами автоматизації. Як результат, майже буль-який пристрій можна інтегрувати в нього з підтримкою багатьох протоколів і стандартів комунікації"}
              </div>
            </div>
          </div>
          <div className={styles.automation_dark_4}>
            <img
              className={styles.automation_foto_4}
              src={automation_4}
              alt="automation touchscreen touchpanel Crestron"
            ></img>
            <div
              className={styles.automation_row_9}
              styles="display: table-row"
            >
              <div className={styles.automation_numeric_9}>09/</div>
              <div className={styles.automation_paragraph_4_1}>
                {lang
                  ? "The ability to 'build up' the integrated system, both horizontally and vertically"
                  : "Можливість 'нарощування' інтегрованої системи, як у горизонтальній, так і у вертикальній площинах"}
              </div>
            </div>
            <div
              className={styles.automation_row_10}
              styles="display: table-row"
            >
              <div className={styles.automation_numeric_10}>10/</div>
              <div className={styles.automation_paragraph_4_2}>
                {lang
                  ? "The home automation system 'adapts' to your life rhythm and executes exactly the scenarios you want"
                  : "Система домашньої автоматизації 'підлаштовується' під ваш життєвий ритм та виконує саме ті сценарії, які ви побажали"}
              </div>
            </div>
            <div
              className={styles.automation_row_11}
              styles="display: table-row"
            >
              <div className={styles.automation_numeric_11}>11/</div>
              <div className={styles.automation_paragraph_4_3}>
                {lang
                  ? "The system independently coordinates the operation of the engineering system with maximum energy efficiency"
                  : "Система самостійно, з максимальною енергоефективністю, погоджує роботу інженерної системи"}
              </div>
            </div>
            <div
              className={styles.automation_row_12}
              styles="display: table-row"
            >
              <div className={styles.automation_numeric_12}>12/</div>
              <div className={styles.automation_paragraph_4_4}>
                {lang
                  ? "Controls the devices in the system: learns your habits, freeing you from routine, repetitive and distracting actions, saving you attention, time and resources"
                  : "Проконтролює пристрої в системі: вивчить ваші звички, звільнивши вас від рутини, однакових дій, що відволікають, економлячи увагу, час і ресурси"}
              </div>
            </div>
          </div>
          <div className={styles.automation_dark_5}>
            <div className={styles.automation_light_palochka_2}></div>
            <div className={styles.automation_paragraph_5}>
              {lang
                ? "A smart home is an infinity of possibilities and quality improvements for your home that function synchronously, smoothly, and invisibly to you, guaranteeing the safety and predictability of the behavior of an integrated system that is controlled by an incredibly simple and easy-to-use panel or your tablet or smartphone."
                : " Розумний будинок - це нескінченність можливостей та якісних удосконалень для дому, що функціонують синхронно, безперебійно та непомітно для вас, гарантуючи безпеку та передбачуваність поведінки інтегрованої системи, в управлінні якої використовується неймовірно проста та зручна у використанні панель, або ж ваш планшет чи смартфон."}
            </div>
          </div>
          <Footer lang={lang} />
        </div>
      </div>
    );
  }
}

export default HomeAutomation;
