import { PureComponent } from "react";
import cur_prj_1 from "../../../img/Vatra House/vatra_1.jpg";
import cur_prj_2 from "../../../img/Vatra House/vatra_2.jpg";
import cur_prj_3 from "../../../img/Vatra House/vatra_3.jpg";
import cur_prj_4 from "../../../img/Vatra House/vatra_4.jpg";
import cur_prj_5 from "../../../img/Vatra House/vatra_5.jpg";
import cur_prj_6 from "../../../img/Vatra House/vatra_6.jpg";
import cur_prj_7 from "../../../img/Vatra House/vatra_7.jpg";
import cur_prj_8 from "../../../img/Vatra House/vatra_8.jpg";
import cur_prj_9 from "../../../img/Vatra House/vatra_9.jpg";
import cur_prj_10 from "../../../img/Vatra House/vatra_10.jpg";
import cur_prj_11 from "../../../img/Vatra House/vatra_11.jpg";
import cur_prj_12 from "../../../img/Vatra House/vatra_12.jpg";
import cur_prj_13 from "../../../img/Vatra House/vatra_13.jpg";
import cur_prj_14 from "../../../img/Vatra House/vatra_14.jpg";
import cur_prj_15 from "../../../img/Vatra House/vatra_15.jpg";
import cur_prj_16 from "../../../img/Vatra House/vatra_16.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class VatraHouse extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [
      cur_prj_1,
      cur_prj_2,
      cur_prj_3,
      cur_prj_4,
      cur_prj_5,
      cur_prj_6,
      cur_prj_7,
      cur_prj_8,
      cur_prj_9,
      cur_prj_10,
      cur_prj_11,
      cur_prj_12,
      cur_prj_13,
      cur_prj_14,
      cur_prj_15,
      cur_prj_16,
    ];

    const idUniq = 240;

    const textDescribe =
      "Vatra House - будинок побудований з використанням системи домашньої автоматизації Crestron. У даному будинку реалізовані завдання управління освітленням, системою опалення та підігрівом підлоги, кондиціюванням та вентиляцією, зашторюванням, а також фоновим озвучуванням приміщень та домашнім кінотеатром. Унікальністю цього проекту є те, що по будинку розташовані термостати Google Nest, які через інтеграцію з мозком «розумного будинку» дозволить налаштовувати всі температурні побажання в кожній кімнаті по окремості, а також встановити температурний розклад для вашого життя у цьому будинку. Архітектор Сергій Махно.";

      const textDescribeEng =
      "Vatra House is a house built using the Crestron home automation system. The house is equipped with lighting control, heating and floor heating, air conditioning and ventilation, curtains, as well as background sound and a home theater. The uniqueness of this project is that there are Google Nest thermostats throughout the house, which, through integration with the smart home brain, will allow you to customize all the temperature wishes in each room individually, as well as set a temperature schedule for your life in this house. Architect Sergey Makhno.";


    return (
      <>
        <CurrentPrj
          name="Vatra house"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default VatraHouse;
