import React from "react";
// import { useEffect } from "react";
// import ReactDOM from "react-dom/client";
import App from "./App";
// import { hydrateRoot, render } from "react-dom";
import { hydrateRoot, createRoot } from "react-dom/client";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//     <App />
// );
function AppWithCallbackAfterRender() {
  // useEffect(() => {
  //   console.log("rendered");
  // });

  return <App />;
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  const root = hydrateRoot(rootElement, <App />);
  root.render(<App />);
  // hydrateRoot(<App />, rootElement);
} else {
  // render(<App />, rootElement);
  const root = createRoot(rootElement);
  root.render(<AppWithCallbackAfterRender />);
}
