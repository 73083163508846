import { PureComponent } from "react";
import styles from "./Footer.module.scss";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import arrowtoTop from "../../img/arrow_to_above_icon.png";

class Footer extends PureComponent {
  scrollto = () => {
    window.scrollTo(0, 0);
  };
  render() {
    const { lang } = this.props;
    return (
      <footer className={styles.root} id="footer">
        <div className={styles.footerLogo}></div>
        <div className={styles.footerMenu}>
          <NavLink
            to={"/"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
            <button className={styles.menuItem} onClick={this.scrollto}>
              {lang ? "          Home" : "ГОЛОВНА"}
            </button>
          </NavLink>

          <NavLink
            to={"/ourProjects"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
            <button className={styles.menuItem}>
              {lang ? "Our projects" : "НАШІ ПРОЄКТИ"}
            </button>
          </NavLink>
          <Link to={`/main#purpose`} style={{ textDecoration: "none" }}>
            <button className={styles.menuItem}>
              {lang ? "Services" : "ПОСЛУГИ"}
            </button>
          </Link>
          <Link to={`/main#describe`} style={{ textDecoration: "none" }}>
            <button className={styles.menuItem}>
              {lang ? "How we work" : "ЯК МИ ПРАЦЮЄМО"}
            </button>
          </Link>
          {lang ? (
            <NavLink
              to={"/feedback_en"}
              style={({ isActive, isPending }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isPending ? "darkgrey" : "black",
                  textDecoration: "none",
                };
              }}
            >
              <button className={styles.menuItem}>
                Contact us
              </button>
            </NavLink>
          ) : (
            <NavLink
              to={"/feedback"}
              style={({ isActive, isPending }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isPending ? "darkgrey" : "black",
                  textDecoration: "none",
                };
              }}
            >
              <button className={styles.menuItem}>
                ЗВ'ЯЗАТИСЯ З НАМИ
              </button>
            </NavLink>
          )}
        </div>
        <div className={styles.address}>TETRIS HALL, 1/2 DILOVA STR., KYIV</div>
        <a className={styles.phoneNumber} href="tel:+380676560909">
          (+380676560909)
        </a>
        <a className={styles.mail} href="mailto:info@d8.com.ua">
          info@d8.com.ua
        </a>
        <div className={styles.authorRights}>
          {lang
            ? "   ©2023 D8 ENGINEERING. All rights reserved"
            : "©2023 D8 ENGINEERING. ВСІ ПРАВА ЗАХИЩЕНІ"}
        </div>
        <button className={styles.scrollToTop} onClick={() => this.scrollto()}>
          <img
            className={styles.arrowTop}
            src={arrowtoTop}
            alt="arrow scroll to top"
          ></img>
        </button>
        <div className={styles.authorCondition}>
          {lang ? "TERMS AND CONDITIONS" : "ПРАВИЛА ТА УМОВИ"}
        </div>
      </footer>
    );
  }
}

export default Footer;
