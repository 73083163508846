import { PureComponent } from "react";
import styles from "./BusinessSolution.module.scss";
import Footer from "../../components/Footer/Footer";
import business_1 from "../../img/business-1.jpg";
import business_2 from "../../img/business-2.jpg";
import business_3 from "../../img/business-3.jpg";
import business_4 from "../../img/business-4.jpg";
import { Helmet } from "react-helmet";

class BusinessSolution extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.engineRoot}>
        <Helmet>
          <title>{lang ? "Business solution" : "Рішення для бізнесу"}</title>
          <meta
            name="description"
            content="Комплексне рішення для проведення високоефективної колективної роботи та нарад | Організація відео- або аудіоконференцій та презентацій в один дотик через Microsoft Teams або відеокімнат ZOOM для бізнесу | бронювання переговорних приміщень | Автоматизація офісного простору від D8engineering | Crestron"
          />
        </Helmet>
        <div className={styles.engineer_proj}>
          <div className={styles.business}>
            <div className={styles.business_dark_1}>
              <div className={styles.business_palochka}></div>
              <h1 className={styles.business_text_chapter}>
                {lang ? "BUSINESS SOLUTIONS" : "РІШЕННЯ ДЛЯ БІЗНЕСУ"}
              </h1>
              <p className={styles.business_describe_chapter}>
                {lang
                  ? "A comprehensive solution for highly effective teamwork and meetings"
                  : "Комплексне рішення для проведення високоефективної колективної роботи та нарад"}
              </p>
            </div>
            <div className={styles.business_light_1}>
              <img
                className={styles.business_foto_1}
                src={business_1}
                alt="business office meeting"
              ></img>
              <div className={styles.business_paragraph_1}>
                {lang
                  ? "Reliable, efficient, and innovative conference communication solutions allow you to focus on your work and increase the productivity of your meeting space."
                  : "  Надійні, ефективні та інноваційні рішення організації конференц-зв'язку дозволять зосередитись на роботі та підвищити плідність переговорного простору."}
                <br></br>
                <br></br>
                {lang
                  ? "Systems with built-in presence sensors allow you to automatically control systems in meeting rooms and save energy, collecting data on the use of the room."
                  : "Системи з вбудованим датчиком присутності дозволяють автоматично керувати системами в переговорних та економити енергію, забезпечувати збір даних про використання приміщення."}
                <br></br>
                <br></br>
                {lang
                  ? "One-touch video or audio conferencing and presentations via Microsoft Teams or ZOOM video rooms for business."
                  : "Організація відео- або аудіоконференцій та презентацій в один дотик через Microsoft Teams або відеокімнат ZOOM для бізнесу."}
              </div>
            </div>
            <div className={styles.business_light_2}>
              <img
                className={styles.business_foto_2}
                src={business_2}
                alt="touchpanel office"
              ></img>
              <div className={styles.business_paragraph_2}>
                {lang
                  ? "A solution with two screens for comparison and analysis will be indispensable for meetings. And integrated control of AV equipment, lighting, curtains and thermostats will create a comfortable environment"
                  : "Незамінним у нарадах стане рішення із двома екранами для порівнення та аналізу. А інтегроване керування АВ-обладнання, освітленням, шторами та термостатами дозволить створити комфортну атмосферу"}
                <br></br>
                <br></br>
                {lang
                  ? "New network video decoders/DM NVX decoders provide reliable content protection while distributing the highest quality video signal throughout the office, including dynamic spaces for various purposes, and allow the use of 1-gigabit network infrastructure."
                  : "Нові мережеві відеодекодери/декодери DM NVX забезпечують надійний захист контенту при розподілі відеосигналу найвищої якості по всьому офісу, включаючи динамічні простори різного призначення, і дозволяють використовувати інфраструктуру 1-гігабітної мережі."}
              </div>
            </div>
            <div className={styles.business_dark_3}>
              <img
                className={styles.business_foto_3}
                src={business_3}
                alt="Crestron office meeting"
              ></img>
              <div className={styles.business_paragraph_3}>
                {lang
                  ? "Wirelessly share data using a tablet, smartphone, or laptop: the technology allows you to transmit uncompressed 4K video (60 Hz) to multiple displays simultaneously with minimal latency."
                  : "Обмін даними бездротовою мережею за допомогою планшета, смартфона або ноутбука: технології дозволяють передавати нестиснуті 4К відео (60 Гц) одночасно на декілька дисплеїв з мінімальною затримкою."}
                <br></br>
                <br></br>
                {lang
                  ? "It is incredibly convenient to conduct presentations from personal devices using AirMedia or HDMI connections, make calls via Bluetooth or using the built-in conference phone based on the fully open SIP protocol. Professional 360° microphones and speakers provide unrivaled sound quality for all participants."
                  : "Неймовірно зручним є можливість проведення презентацій з особистих пристроїв за допомогою підключення AirMedia або HDMI, здійснення дзвінків по Bluetooth або за допомогою вбудованого конференц-телефону на базі повністю відкритого протоколу SIP. Професійні мікрофони 360° та динаміки забезпечують неперевершену якість звуку для всіх учасників."}
              </div>
            </div>
            <div className={styles.business_dark_4}>
              <img
                className={styles.business_foto_4}
                src={business_4}
                alt="meeting present hall"
              ></img>
              <div className={styles.business_paragraph_4}>
                {lang
                  ? "The possibility of booking meeting rooms is interesting and convenient: an on-screen interface with a meeting room availability calendar and simple instructions for getting started"
                  : "Цікавою та зручною є можливість бронювання переговорних приміщень: екранний інтерфейс з календарем доступності переговорної та простими інструкціями для початку роботи."}

                <br></br>
                <br></br>
                {lang
                  ? "Automation of office space will improve the efficiency and quality of conferences, cooperation, and teamwork."
                  : "Автоматизація офісного простору дозволить підвищити ефективність та якість конференцій, співробітництва та колективної роботи."}
              </div>
            </div>
          </div>
          <Footer lang={lang} />
        </div>
      </div>
    );
  }
}

export default BusinessSolution;
