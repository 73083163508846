import { PureComponent } from "react";
import cur_prj_1 from "../../../img/Shkrub House/shkrub_1.jpg";
import cur_prj_2 from "../../../img/Shkrub House/shkrub_2.jpg";
import cur_prj_3 from "../../../img/Shkrub House/shkrub_3.jpg";
import cur_prj_4 from "../../../img/Shkrub House/shkrub_4.jpg";
import cur_prj_5 from "../../../img/Shkrub House/shkrub_5.jpg";
import cur_prj_6 from "../../../img/Shkrub House/shkrub_6.jpg";
import cur_prj_7 from "../../../img/Shkrub House/shkrub_7.jpg";
import cur_prj_8 from "../../../img/Shkrub House/shkrub_8.jpg";
import cur_prj_9 from "../../../img/Shkrub House/shkrub_9.jpg";
import cur_prj_10 from "../../../img/Shkrub House/shkrub_10.jpg";
import cur_prj_11 from "../../../img/Shkrub House/shkrub_11.jpg";
import cur_prj_12 from "../../../img/Shkrub House/shkrub_12.jpg";
import cur_prj_13 from "../../../img/Shkrub House/shkrub_13.jpg";
import cur_prj_14 from "../../../img/Shkrub House/shkrub_14.jpg";
import cur_prj_15 from "../../../img/Shkrub House/shkrub_15.jpg";
import cur_prj_16 from "../../../img/Shkrub House/shkrub_16.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class ShkrubHouse extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [
      cur_prj_1,
      cur_prj_2,
      cur_prj_3,
      cur_prj_4,
      cur_prj_5,
      cur_prj_6,
      cur_prj_7,
      cur_prj_8,
      cur_prj_9,
      cur_prj_10,
      cur_prj_11,
      cur_prj_12,
      cur_prj_13,
      cur_prj_14,
      cur_prj_15,
      cur_prj_16,
    ];

    const idUniq = 239;

    const textDescribe =
      "Shkrub House це найновіша інтерпретація українського будинку, в якому гармонійно співіснують сучасна система автоматизації та дизайну. Система автоматизації Crestron у вигляді керування світлом та кліматом доповнює унікальний дизайн та спрощує побут. Інтеграція акустики Sonos у систему Crestron сприяє підтримці гарного настрою завдяки автоматичному відтворенню улюбленої музики. Архітектор Сергій Махно.";

      const textDescribeEng =
      "Shkrub House is the latest interpretation of the Ukrainian house, where modern automation and design coexist in harmony. The Crestron automation system in the form of light and climate control complements the unique design and simplifies everyday life. The integration of Sonos speakers into the Crestron system helps to maintain a good mood by automatically playing your favorite music. Architect Sergey Makhno.";

    return (
      <>
        <CurrentPrj
          name="Shkrub house"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default ShkrubHouse;
