import { PureComponent } from "react";
import styles from "./LookAtUs.module.scss";
import VideoCarousel from "../VideoCarousel/VideoCarousel";

class LookAtUs extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.titleGeneral}>
          <div className={styles.title}>
            {lang ? "We are in action" : "Ми у дії"}
          </div>
          <div className={styles.subTitle}>
            {lang ? "Look at us" : "Подивись на нас"}
          </div>
        </div>
        <div className={styles.carouselVideo}>
          <VideoCarousel />
        </div>
      </div>
    );
  }
}

export default LookAtUs;
