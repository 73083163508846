import { PureComponent } from "react";
import styles from "./CreativeView.module.scss";
import PrpsButton from "../PrpsButton/PrpsButton";

class CreativeView extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.titleGeneral}>
          <div className={styles.title}>
            {lang ? "We offer    " : "Ми пропонуємо"}
          </div>
          <div className={styles.subTitle}>
            <div className={styles.subTitle_first}>
              {lang ? "CREATIVE VISION +" : "Креативне бачення +"}
            </div>
            <div className={styles.subTitle_second}>
              {lang ? " TECHNOLOGIES" : "технології"}
            </div>
          </div>
        </div>
        <div className={styles.prpsBtn}>
          <PrpsButton gridArea={styles.btn1Prps} linkPath={"homeautomation"}>
            {lang ? "Home automation" : "Домашня автоматизація"}
          </PrpsButton>
          <PrpsButton gridArea={styles.btn2Prps} linkPath={"engineeringdesign"}>
            {lang ? "Engineering design" : "Інженерне проєктування"}
          </PrpsButton>
          <PrpsButton
            gridArea={styles.btn3Prps}
            linkPath={"energyefficientDecision"}
          >
            {lang ? "Energy-efficient solutions" : "Енергоефективні рішення"}
          </PrpsButton>
          <PrpsButton gridArea={styles.btn4Prps} linkPath={"cinema"}>
            {lang ? "Personal cinemas" : "Персональні кінотеатри"}
          </PrpsButton>
          <PrpsButton gridArea={styles.btn5Prps} linkPath={"businessSolution"}>
            {lang ? "Business solutions" : "Рішення для бізнесу"}
          </PrpsButton>
          <PrpsButton gridArea={styles.btn6Prps} linkPath={"educationSolution"}>
            {lang ? "Training solutions" : "Рішення для навчання"}
          </PrpsButton>
          <PrpsButton gridArea={styles.btn7Prps} linkPath={"powerreservation"}>
            {lang ? "Power redundancy" : "Резервація живлення"}
          </PrpsButton>
          <PrpsButton gridArea={styles.btn8Prps} linkPath={"audiosolution"}>
            {lang ? "Audio and multiroom systems" : "Аудіо та мультирум системи"}
          </PrpsButton>
        </div>
      </div>
    );
  }
}

export default CreativeView;
