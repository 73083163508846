import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import styles from './CustomInput.module.scss';

const CustomInput = (props) => {
    const { type, placeholder, label, field, form } = props;
    const { name, value } = field;
    const { touched, errors } = form;

    return useMemo(() => (
        <div className={styles.root}>
            {label && <label htmlFor={name}>{label}</label>}
            <input
                name={name}
                id={name}
                type={type}
                placeholder={placeholder}
                value={value}
                {...field}
            />
            <span className="error">{touched[name] && errors[name] ? errors[name] : ''}</span>
          
        </div>
        // eslint-disable-next-line
    ), [value, touched, errors])   
}

CustomInput.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
};
CustomInput.defaultProps = {
    type: 'text',
    placeholder: '',
    label: '',
};

export default CustomInput;