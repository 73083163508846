import { PureComponent } from "react";
import PropTypes from "prop-types";
import styles from "./ButtonMenuAnchor.module.scss";
import { HashLink as Link } from "react-router-hash-link";

class ButtonMenuAnchor extends PureComponent {
  render() {
    const { children, onClick, linkGo } = this.props;

    return (
      <>
        <Link to={`/main#${linkGo}`} style={{ textDecoration: "none" }}>
          <button
            onClick={onClick}
            // className={`${styles.btn} ${pressedStyle}`}
            className={styles.btn}
          >
            {children}
          </button>
        </Link>
      </>
    );
  }
}

ButtonMenuAnchor.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onClick: PropTypes.func,
  linkGo: PropTypes.string,
};

ButtonMenuAnchor.defaultProps = {
  onClick: () => {},
};

export default ButtonMenuAnchor;
