import React from "react";
import styles from "./OurBrands.module.scss";
import { NavLink } from "react-router-dom";

const OurBrands = (props) => {
  const { lang } = props;
  return (
    <div className={styles.root}>
      <div className={styles.title}>{lang ? "OUR BRANDS" : " НАШІ БРЕНДИ"}</div>
      <NavLink
        to={"https://brands.d8.com.ua/"}
        target="_blank"
        style={({ isActive, isPending }) => {
          return {
            fontWeight: isActive ? "bold" : "",
            color: isPending ? "darkgrey" : "black",
            textDecoration: "none",
          };
        }}
      >
        <div className={styles.brands_img}></div>
      </NavLink>
    </div>
  );
};

export default OurBrands;
