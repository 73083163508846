import { PureComponent } from "react";
import cur_prj_1 from "../../../img/Sergey Mahno Architects Office/makhno_1.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class MakhnoOffice extends PureComponent {
  render() {
    const { lang } = this.props;
    const imgEl = [cur_prj_1];
    const idUniq = 234;
    const textDescribe =
      "Майстерня поєднує в собі шоу-рум і місце для роботи та відпочинку. За допомогою встановленої системи автоматизації Crestron, що дозволяє керувати сиситемою використовуючи мобільний телефон, щоб увімкнути/вимкнути певні групи освітлення, виставити темепературне завдання на термостаті та керувати гучністю музики у приміщеннях.";

      const textDescribeEng =
      "The workshop combines a showroom and a place to work and relax. With the help of the installed Crestron automation system, which allows you to control the system using a mobile phone to turn on/off certain lighting groups, set the temperature task on the thermostat and control the volume of music in the rooms.";

    return (
      <>
        <CurrentPrj
          name="Makhno office"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default MakhnoOffice;
