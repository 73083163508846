import { useEffect } from "react";
import styles from "./Banner.module.scss";

export default function Banner(props) {

  const {lang} = props;

  useEffect(() => {
    var elem_1 = document.getElementById("creative");
    var elem_2 = document.getElementById("engineer");
    var elem_3 = document.getElementById("technology");
    var elem_4 = document.getElementById("efficient");
    var elem_5 = document.getElementById("allService");
    var elem_6 = document.getElementById("design");
    var elem_7 = document.getElementById("d");
    var elem_8 = document.getElementById("8");
    var elem_9 = document.getElementById("company");

   
   
    const timeout_1 = setTimeout(() => {
      elem_1.classList.add(`${styles.visibleBlock}`);
    }, 0);
    const timeout_11 = setTimeout(() => {
      elem_1.classList.add(`${styles.visibleBlock_2}`);
    }, 50);
    const timeout_2 = setTimeout(() => {
      elem_2.classList.add(`${styles.visibleBlock}`);
    }, 0);
    const timeout_21 = setTimeout(() => {
      elem_2.classList.add(`${styles.visibleBlock_2}`);
    }, 400);
    const timeout_3 = setTimeout(() => {
      elem_3.classList.add(`${styles.visibleBlock}`);
    }, 0);
    const timeout_31 = setTimeout(() => {
      elem_3.classList.add(`${styles.visibleBlock_2}`);
    }, 800);
    const timeout_4 = setTimeout(() => {
      elem_4.classList.add(`${styles.visibleBlock}`);
    }, 0);
    const timeout_41 = setTimeout(() => {
      elem_4.classList.add(`${styles.visibleBlock_2}`);
    }, 1200);
    const timeout_5 = setTimeout(() => {
      elem_5.classList.add(`${styles.visibleBlock}`);
    }, 0);
    const timeout_51 = setTimeout(() => {
      elem_5.classList.add(`${styles.visibleBlock_2}`);
    }, 1600);
    const timeout_6 = setTimeout(() => {
      elem_6.classList.add(`${styles.visibleBlock}`);
    }, 0);
    const timeout_61 = setTimeout(() => {
      elem_6.classList.add(`${styles.visibleBlock_2}`);
    }, 2000);
    const timeout_7 = setTimeout(() => {
      elem_7.classList.add(`${styles.visibleD8}`);
    }, 0);
    const timeout_71 = setTimeout(() => {
      elem_7.classList.add(`${styles.visibleD8_2}`);
    }, 2300);
    const timeout_8 = setTimeout(() => {
      elem_8.classList.add(`${styles.visibleD8}`);
    }, 0);
    const timeout_81 = setTimeout(() => {
      elem_8.classList.add(`${styles.visibleD8_2}`);
    }, 2300);
    const timeout_9 = setTimeout(() => {
      elem_9.classList.add(`${styles.visibleD8}`);
    }, 0);
    const timeout_91 = setTimeout(() => {
      elem_9.classList.add(`${styles.visibleD8_3}`);
    }, 2700);


    const timeout = setTimeout(() => {
      elem_1.classList.add(`${styles.visibleAnimation}`);
      elem_1.classList.remove(`${styles.visibleBlock_2}`);
      elem_2.classList.add(`${styles.visibleAnimation}`);
      elem_2.classList.remove(`${styles.visibleBlock_2}`);
      elem_3.classList.add(`${styles.visibleAnimation}`);
      elem_3.classList.remove(`${styles.visibleBlock_2}`);
      elem_4.classList.add(`${styles.visibleAnimation}`);
      elem_4.classList.remove(`${styles.visibleBlock_2}`);
      elem_5.classList.add(`${styles.visibleAnimation}`);
      elem_5.classList.remove(`${styles.visibleBlock_2}`);
      elem_6.classList.remove(`${styles.visibleBlock}`);
      elem_7.classList.remove(`${styles.visibleD8}`);
      elem_8.classList.remove(`${styles.visibleD8}`);
    }, 2900);

    const timeout_v2 = setTimeout(() => {
      elem_9.classList.remove(`${styles.visibleD8}`);
    }, 3200);

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout_1);
      clearTimeout(timeout_2);
      clearTimeout(timeout_3);
      clearTimeout(timeout_4);
      clearTimeout(timeout_5);
      clearTimeout(timeout_6);
      clearTimeout(timeout_7);
      clearTimeout(timeout_8);
      clearTimeout(timeout_9);
      clearTimeout(timeout_11);
      clearTimeout(timeout_21);
      clearTimeout(timeout_31);
      clearTimeout(timeout_41);
      clearTimeout(timeout_51);
      clearTimeout(timeout_61);
      clearTimeout(timeout_71);
      clearTimeout(timeout_81);
      clearTimeout(timeout_91);
      clearTimeout(timeout_v2);
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.imgContainer}>
          <span className={styles.Img_D} id="d"></span>
          <span className={styles.Img_8} id="8"></span>
          <span className={styles.bannerNameCompany800} id="company">
            ENGINEERING
          </span>
        </div>

        <div className={styles.bannerNameCompany}>ENGINEERING</div>
        <div className={styles.bannerCreative} id="creative">
          {lang ? "    Creative" : "Креатив"}
        </div>
        <div className={styles.bannerEngineer} id="engineer">
          {lang ? "  Engineering" : "Інженерія"}
        </div>
        <div className={styles.bannerTechnology} id="technology">
          {lang ? "Technologies" : "Технології"}
        </div>
        <div className={styles.bannerEfficient} id="efficient">
          {lang ? "         Efficiency" : "Ефективність"}
        </div>
        <div className={styles.bannerFullService} id="allService">
          {lang ? "         Full range of services" : "Повний комплекс послуг"}
        </div>
        <div className={styles.bannerText} id="design">
          {lang ? "              Designing and automation" : "  Проєктування та автоматизація"}
        </div>
      </div>
    </div>
  );
}
