import { PureComponent } from "react";
import styles from "./OurVision.module.scss";
import Footer from "../../components/Footer/Footer";
import strategy_1 from "../../img/strategy-1.jpg";
import strategy_1_1 from "../../img/strategy-1_1.jpg";
import strategy_2 from "../../img/strategy-2.jpg";
import strategy_3 from "../../img/strategy-3.jpg";
import strategy_4 from "../../img/strategy-4.jpg";
import strategy_5 from "../../img/strategy-5.jpg";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

class OurVision extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.engineRoot}>
        <Helmet>
          <title>{lang ? "Our vision" : "Наше бачення"}</title>
          <meta
            name="description"
            content="D8engineering: наша стратегія | наше бачення | Проєктування та розробка концепції автоматизації процесів роботи інженерних систем вашого будинку - освітлення, опалення, вентиляції, кондиціювання, поливу та інших необхідних систем Crestron"
          />
           <link rel="canonical" href="https://d8.com.ua/ourvision" />
        </Helmet>
        <div className={styles.engineer_proj}>
          <div className={styles.strategy}>
            <div className={styles.strategy_light_1}>
              <h1 className={styles.strategy_text_chapter}>
                {lang ? "PROCESS VISION" : "БАЧЕННЯ ПРОЦЕСУ"}
              </h1>
              <h1 className={styles.strategy_01}>01</h1>
              <div className={styles.strategy_palochka_01}></div>
              <h1 className={styles.strategy_chapter_01}>
                {lang ? "STRATEGY" : "СТРАТЕГІЯ"}
              </h1>
            </div>
            <div className={styles.strategy_dark_01}>
              <img
                className={styles.strategy_foto_1}
                src={strategy_1}
                alt="strategy moon circle"
              ></img>
              <img
                className={styles.strategy_foto_1_1}
                src={strategy_1_1}
                alt="strategy idea circle 1"
              ></img>
              <div className={styles.strategy_text_01}>
                {lang ? "Each space is unique and has different functional purposes and needs. Therefore, we will create controlled comfort according to your mood and wishes, combining the highest degree of quality, safety, practicality and professional design solutions." : "Кожен простір унікальний і має різне функціональне призначення та потреби. Тому ми створимо керований комфорт відповідно до вашого настрою та побажань, які поєднує в собі найвищий ступінь якості, безпеки, практичності та професійних дизайнерських рішень."}
              </div>
              <div className={styles.strategy_text_01_1}>
                {lang ? "After learning everything about your space, functional and aesthetic needs and wishes. Having discussed all the terms of cooperation, we can proceed to the concept development stage." : "Дізнавшись все про ваш простір, функціональні та естетичні потреби та побажання. Обговоривши всі умови співпраці, ми можемо приступити до етапу розробки концепції."}
              </div>
            </div>
            <div className={styles.strategy_light_2}>
              <h1 className={styles.strategy_02}>02</h1>
              <div className={styles.strategy_palochka_02}></div>
              <h1 className={styles.strategy_chapter_02}>
                {lang ? "CONCEPT" : "КОНЦЕПЦІЯ"}
              </h1>
            </div>
            <div className={styles.strategy_dark_02}>
              <img
                className={styles.strategy_foto_2}
                src={strategy_2}
                alt="strategy idea lines"
              ></img>
              <div className={styles.strategy_text_02}>
                {lang ? "Designing and developing a concept for automating the processes of your home's engineering systems - lighting, heating, ventilation, air conditioning, irrigation, and other necessary systems." : "Проєктування та розробка концепції автоматизації процесів роботи інженерних систем вашого будинку - освітлення, опалення, вентиляції, кондиціювання, поливу та інших необхідних систем."}
              </div>
            </div>
            <div className={styles.strategy_dark_3}>
              <h1 className={styles.strategy_03}>03</h1>
              <div className={styles.strategy_palochka_03}></div>
              <h1 className={styles.strategy_chapter_03}>
                {lang ? "DEVELOPMENT" : "РОЗРОБКА"}
              </h1>
            </div>
            <div className={styles.strategy_light_03}>
              <img
                className={styles.strategy_foto_3}
                src={strategy_3}
                alt="strategy thoughts"
              ></img>
              <div className={styles.strategy_text_03}>
                {lang ? "Development of a complete environment automation project." : "Розробка повного проєкта автоматизації середовища."}
              </div>
              <div className={styles.strategy_text_03_1}>
                {lang ? "Integration of all necessary automation systems. Installation of all necessary equipment for lighting, air conditioning, temperature and motion sensors, heating, music, cinemas, security and access control systems." : "  Інтеграція усіх необхідних систем автоматизації. Інсталяція усього необхідного обладнання освітлення, кондиціювання, датчиків температури та руху, опалення, музики, кінотеатрів, систем безпеки та контролю доступа."}
              </div>
            </div>
            <div className={styles.strategy_dark_4}>
              <h1 className={styles.strategy_04}>04</h1>
              <div className={styles.strategy_palochka_04}></div>
              <h1 className={styles.strategy_chapter_04}>
                {lang ? "OPTIMIZATION" : "ОПТИМІЗАЦІЯ"}
              </h1>
            </div>
            <div className={styles.strategy_dark_04}>
              <img
                className={styles.strategy_foto_4}
                src={strategy_4}
                alt="strategy thoughts color"
              ></img>
              <div className={styles.strategy_text_04}>
                {lang ? "Our optimization process includes several stages aimed at improving performance and the overall user experience." : "Наш процес оптимізації охоплює декілька етапів, спрямованих на поліпшення продуктивності та загального досвіду користувача."}
              </div>
              <div className={styles.strategy_text_04_1}>
                {lang ? "Setting specific optimization goals, such as increasing system speed, improving the user interface, reducing energy consumption, and other aspects." : " Встановлення конкретних цілей оптимізації, як-от збільшення швидкості роботи систем, поліпшення користувацького інтерфейсу, зниження енергоспоживання та інші аспекти."}
              </div>
            </div>
            <div className={styles.strategy_light_5}>
              <h1 className={styles.strategy_05}>05</h1>
              <div className={styles.strategy_palochka_05}></div>
              <h1 className={styles.strategy_chapter_05}>
                {lang ? "TESTING" : "ТЕСТУВАННЯ"}
              </h1>
            </div>
            <div className={styles.strategy_dark_05}>
              <img
                className={styles.strategy_foto_5}
                src={strategy_5}
                alt="strategy idea design"
              ></img>
              <div className={styles.strategy_text_05}>
                {lang ? "We do not experiment on our clients." : "Ми не експерементуємо на своїх клієнтах."}
              </div>
              <div className={styles.strategy_text_05_1}>
                {lang ? "Each system is tested in detail by D8 Engineering specialists. During testing, we simulate the process of live operation of the environment so that your every day is filled with only positive emotions." : " Кожна система детально тестується спеціалістами D8 Engineering. При тестуванні ми імітуємо процес живої експлуатації середовища, щоб ваш кожен день був наповнений лише позитивними емоціями."}
              </div>
            </div>
            <h1 className={styles.strategy_text}>
              {lang ? "Are you interested in the Process?" : "Зацікавив Процес?"}
            </h1>
            <div className={styles.strategy_light_palochka}></div>
            <NavLink
              to="/feedback"
              style={({ isActive, isPending }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isPending ? "darkgrey" : "black",
                  textDecoration: "none",
                };
              }}
            >
              <button className={styles.strategy_btn_write_us}>
                {lang ? "Contact us" : "ЗВ'ЯЗАТИСЬ З НАМИ"}
              </button>
            </NavLink>
          </div>
          <Footer lang={lang}/>
        </div>
      </div>
    );
  }
}

export default OurVision;
