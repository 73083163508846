import { PureComponent } from "react";
import styles from "./SliderPrj.module.scss";
import { NavLink } from "react-router-dom";
import "./slick.css";
import "./slick-theme.css";
import Slider from "react-slick";
import cur_ph_1 from "../../img/Mod Apartment/mod_9.jpg";
import cur_ph_2 from "../../img/Penthouse Skyline/pentSky_11.jpg";
import cur_ph_3 from "../../img/all-3.jpg";
import cur_ph_4 from "../../img/all-4.jpg";
import cur_ph_5 from "../../img/all-5.jpg";
import cur_ph_6 from "../../img/all-6.jpg";
import cur_ph_7 from "../../img/all-7.jpg";
import cur_ph_8 from "../../img/all-8.jpg";
import cur_ph_9 from "../../img/all-9.jpg";
import cur_ph_10 from "../../img/all-10.jpg";
import cur_ph_11 from "../../img/all-11.jpg";
import cur_ph_12 from "../../img/all-12.jpg";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", zIndex: "3" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "1px" }}
      onClick={onClick}
    />
  );
}


class SliderPrj extends PureComponent {
  render() {
    const imgEl = [cur_ph_1, cur_ph_2, cur_ph_3, cur_ph_4, cur_ph_5, cur_ph_6, cur_ph_7, cur_ph_8, cur_ph_9, cur_ph_10, cur_ph_11, cur_ph_12 ];

    const navEl = ["/project_c235", "/project_c237", "/project_c234", "/project_c239", "/project_c231", "/project_c236", "/project_c240", "/project_c232", "/project_c233", "/project_c238", "/project_c241", "/project_c242" ];

    const settings_1 = {
      dots: false,
      infinite: true,
      centerMode: true,
      centerPadding: "0px",
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: false,
            infinite: true,
            centerMode: true,
            arrows: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
      ],
    };
    const settings_2 = {
      dots: false,
      infinite: true,
      centerMode: true,
      centerPadding: "0px",
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 4,
      autoplay: true,
      autoplaySpeed: 1500,
      pauseOnHover: true,
      rtl:true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: false,
            infinite: true,
            centerMode: true,
            arrows: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
      ],
    };
    const settings_3 = {
      dots: false,
      infinite: true,
      centerMode: true,
      centerPadding: "0px",
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 6,
      autoplay: true,
      autoplaySpeed: 2500,
      pauseOnHover: true,
      rtl: false,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: false,
            infinite: true,
            centerMode: true,
            arrows: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
      ],
    };
    return (
      <div className={styles.root}>
        <div className={styles.slider_main}>
    
          <Slider {...settings_1} style={{width: "100vw"}}>
                      {imgEl.map((el, index) => (
                         <NavLink
                         to={navEl[index]}
                         key={index}
                         style={({ isActive, isPending }) => {
                           return {
                             fontWeight: isActive ? "bold" : "",
                             color: isPending ? "darkgrey" : "black",
                             textDecoration: "none",
                           };
                         }}
                       >
                        <img
                          key={index}
                          className={styles.cur_foto}
                          src={el}
                          alt={index}
                        ></img>
                            </NavLink>
                      ))}
                        
                         </Slider>
                         </div>
                         <div className={styles.slider_main}>
                         <Slider {...settings_2} style={{width: "100vw"}}>
                         {imgEl.map((el, index) => (
                             <NavLink
                             to={navEl[index]}
                             key={index}
                             style={({ isActive, isPending }) => {
                               return {
                                 fontWeight: isActive ? "bold" : "",
                                 color: isPending ? "darkgrey" : "black",
                                 textDecoration: "none",
                               };
                             }}
                           >
                        <img
                          key={index}
                          className={styles.cur_foto}
                          src={el}
                          alt={index}
                        ></img>
                         </NavLink>
                      ))}
                         </Slider>
                         </div>
                         <div className={styles.slider_main}>
                         <Slider {...settings_3} style={{width: "100vw"}}>
                         {imgEl.map((el, index) => (
                             <NavLink
                             to={navEl[index]}
                             key={index}
                             style={({ isActive, isPending }) => {
                               return {
                                 fontWeight: isActive ? "bold" : "",
                                 color: isPending ? "darkgrey" : "black",
                                 textDecoration: "none",
                               };
                             }}
                           >
                        <img
                          key={index}
                          className={styles.cur_foto}
                          src={el}
                          alt={index}
                        ></img>
                         </NavLink>
                      ))}
                         </Slider>
                         </div>
                         <div className={styles.slider_main}>
                         <Slider {...settings_2} style={{width: "100vw"}}>
                         {imgEl.map((el, index) => (
                             <NavLink
                             to={navEl[index]}
                             key={index}
                             style={({ isActive, isPending }) => {
                               return {
                                 fontWeight: isActive ? "bold" : "",
                                 color: isPending ? "darkgrey" : "black",
                                 textDecoration: "none",
                               };
                             }}
                           >
                        <img
                          key={index}
                          className={styles.cur_foto}
                          src={el}
                          alt={index}
                        ></img>
                         </NavLink>
                      ))}
                         </Slider>
                         </div>
       
              

      </div>
    );
  }
}

export default SliderPrj;
