import { PureComponent } from "react";
import cur_prj_1 from "../../../img/SkyLine_112/sky112-1.jpg";
import cur_prj_2 from "../../../img/SkyLine_112/sky112-2.jpg";
import cur_prj_3 from "../../../img/SkyLine_112/sky112-3.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class SkyLine112 extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [
      cur_prj_1,
      cur_prj_2,
      cur_prj_3,
    ];

    const idUniq = 242;

    const textDescribe =
      "Об'єкт оснащений домашньою автоматизацією від Crestron - яка зробила цей проєкт дуже комфортним для замовників - вмикання/вимикання світла по датчику руху, світлові розклади всього об'єкту, керування кліматом та теплими підлогами, керування шторами та ролетами, вбудована аудіосистема. Особлива увага терасі з автоматизованим накриттям, щоб було затишно проводити час у будь-яку погоду. Автоматизовано використання домофона: виведення поточного дзвінка на тач панелі, що розміщені у різних приміщеннях та можливість віддаленого керування вхідними дверима. Також є кінотеатр, що вмикається за допомогою лише однієї кнопки - світло вимикається, штори закриваються та опускається величезний екран для перегляду фільму чи серіалу самому чи в компанії друзів.";

      const textDescribeEng =
      "The facility is equipped with home automation from Crestron, which made this project very comfortable for customers - switching on/off lights by motion sensor, light schedules for the entire facility, climate control and underfloor heating, curtain and roller shutter control, and a built-in audio system. Special attention is paid to the terrace with an automated cover to make it cozy in any weather. The use of the intercom is automated: the current call is displayed on touch panels located in different rooms and the ability to remotely control the front door. There is also a cinema that can be turned on with just one button: the lights turn off, the curtains close, and a huge screen lowers to watch a movie or TV series alone or with friends.";

    return (
      <>
        <CurrentPrj
          name="Modern penthouse"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default SkyLine112;
